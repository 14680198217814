import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';

import { BotMembersDocument, CreateBotMembershipDocument } from 'frontend/api/generated';
import { useIsSubmitting, useToast } from 'frontend/hooks';
import { getArray } from 'frontend/utils';

import NoEligibleUsers from './NoEligibleUsers';
import { ADD_BOT_MEMBERSHIP_QUERY, BOT_PERMISSIONS } from '../../queries';
import AddMemberModal from '../AddMemberModal';
import { getUserName, roleIdsFromFields } from '../AddMemberModal/utils';

const PATH = 'bot.memberships';

type Props = {
  hide: () => Promise<void>;
  args: {
    botId: string;
    organizationId: string;
  };
};
const AddMemberToBot = ({ hide, args: { botId, organizationId } }: Props) => {
  const toast = useToast();
  const [isSubmitting, submitWrapper] = useIsSubmitting();
  const { data, loading: membershipsLoading } = useQuery(ADD_BOT_MEMBERSHIP_QUERY, {
    variables: { id: botId },
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(BOT_PERMISSIONS);
  const allPermissions = get(permissionsData, 'botPermissions', []);
  const roles = useMemo(() => get(data, 'bot.roles', []), [data]);
  const name = useMemo(() => get(data, 'bot.name'), [data]);
  const currentBotMembershipIds = useMemo(() => getArray(PATH, data).map(({ user: { id } }) => id), [data]);
  const currentBotMembershipEmails = useMemo(() => getArray(PATH, data).map(({ user: { email } }) => email), [data]);
  const loading = membershipsLoading || permissionsLoading;

  const eligibleUsers = useMemo(
    () => get(data, 'bot.organization.users', []).filter(({ id }) => !currentBotMembershipIds.includes(id)),
    [currentBotMembershipIds, data],
  );

  const refetchQueries = [{ query: BotMembersDocument, variables: { id: botId } }];
  const [mutate] = useMutation(CreateBotMembershipDocument, { refetchQueries });

  const onSubmit = useCallback(
    async (values) => {
      const selectedEmails = values.selectedUsers.map((user) => user.value);
      const roleIds = roleIdsFromFields(values);
      const users = eligibleUsers.filter((eligibleUser) => selectedEmails.includes(eligibleUser.username));
      const fullNames = users
        .filter((user) => selectedEmails.includes(user.username))
        .map((user) => getUserName(user, user.email))
        .reduce((acc, val, i, arr) => acc + (i < arr.length - 1 ? ', ' : ' and ') + val);
      await Promise.all(users.map((user) => mutate({ variables: { botId, userId: user.id, roleIds } })));

      toast.success(`${fullNames} ${users.length === 1 ? 'was' : 'were'} added to ${name}!`);
      hide();
    },
    [botId, eligibleUsers, hide, mutate, name, toast],
  );

  if (!loading && eligibleUsers.length === 0) return <NoEligibleUsers hide={hide} />;

  return (
    !loading && (
      <AddMemberModal
        users={eligibleUsers}
        existingEmails={currentBotMembershipEmails}
        hide={hide}
        name={name}
        roles={roles}
        allPermissions={allPermissions}
        onSubmit={submitWrapper(onSubmit)}
        isSubmitting={isSubmitting}
        disallowOrganizationRoles
        organizationId={organizationId}
        loading={loading}
      />
    )
  );
};

export default AddMemberToBot;
