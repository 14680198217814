import React from 'react';

import { Modal } from 'frontend/features/Modals';

import styles from './InfoModal.scss';

interface InfoModal {
  hide: () => Promise<void>;
  args: {
    title: string;
    text: string;
  };
}

const InfoModal = ({ hide, args: { title, text } }: InfoModal) => (
  <Modal headerClassName={styles.infoModalHeader} hide={hide} title={title} footer hideCancel>
    <Modal.Content className={styles.infoModalContent}>{text}</Modal.Content>
  </Modal>
);

export default InfoModal;
