import { gql } from '@apollo/client';

export const WebHookFragment = gql`
  fragment Webhook on WebhookType {
    id
    title
    url
    events {
      id
      eventType
    }
  }
`;

export const getBotWebhooks = gql`
  query GetBotWebhooks($botId: ID!) {
    botWebhooks(botId: $botId) {
      ...Webhook
    }
    webhookEventTypeChoices
  }
  ${WebHookFragment}
`;

export const addWebhook = gql`
  mutation AddWebhook(
    $botId: ID!
    $title: String!
    $url: String!
    $event: String!
    $verifyToken: String!
    $challenge: String!
  ) {
    addWebhook(
      botId: $botId
      title: $title
      url: $url
      event: $event
      verifyToken: $verifyToken
      challenge: $challenge
    ) {
      ...Webhook
    }
  }
  ${WebHookFragment}
`;

export const removeWebhook = gql`
  mutation RemoveWebhook($webhookId: ID!) {
    removeWebhook(webhookId: $webhookId) {
      id
    }
  }
`;
