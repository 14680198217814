import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { OrganizationInvitationsDocument } from 'frontend/api/generated';

export default (organizationId) => {
  const { data, loading } = useQuery(OrganizationInvitationsDocument, { variables: { organizationId } });
  const invitations = get(data, 'organizationInvitations', []);

  return { invitations, loading };
};
