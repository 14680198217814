import { Navigate, Route, Routes } from 'react-router-dom';

import { Plus } from 'frontend/assets/icons';
import { Button, PageContent } from 'frontend/components';
import NavLinkTab from 'frontend/components/Tabs/NavLinkTab';
import { useModal } from 'frontend/features/Modals';
import { useTabTitle } from 'frontend/hooks';

import InvitationsList from './InvitationsList/InvitationsList';
import UserList from './UserList/UserList';
import { InviteUser } from '../modals';

const Users = () => {
  useTabTitle('Users');
  const [showInviteModal] = useModal(InviteUser);
  return (
    <div>
      <PageContent>
        <Button icon={Plus} onClick={showInviteModal} className="m-b-md" color="primary">
          Invite member
        </Button>
        <div className="m-b-md">
          <NavLinkTab title="Active" to="active-members" />
          <NavLinkTab title="Invitations" to="invitations" />
        </div>
        <Routes>
          <Route path="/" element={<Navigate replace to="active-members" />} />
          <Route path="/active-users" element={<UserList />} /> {/* Deprecated */}
          <Route path="/active-members" element={<UserList />} />
          <Route path="/invitations" element={<InvitationsList />} />
        </Routes>
      </PageContent>
    </div>
  );
};

export default Users;
