import { get, isFunction, isString, orderBy } from 'lodash';

import type { Role } from 'frontend/features/Organization/types';
import { normalizeKey } from 'frontend/utils';

import { ROLE_ORDER } from '../constants';

const normalizedOrder = ROLE_ORDER.map(normalizeKey);

const getRoleName = (key: string | ((key: Role) => boolean), element) => {
  if (isFunction(key)) return key(element);
  if (isString(key)) return get(element, key);
  return element;
};

/** Sort by index if role found in ROLE_ORDER, otherwise (i.e. custom roles) list them last ordered by name */
const getRoleOrder = (key: string) => (element) => {
  const roleName = getRoleName(key, element);
  const roleIndex = normalizedOrder.findIndex((name) => name === normalizeKey(roleName));

  return `${roleIndex > -1 ? roleIndex : normalizedOrder.length}-${roleName}`;
};

export default function sortRoles(roles: Role[], key: string) {
  const roleOrder = getRoleOrder(key);

  return orderBy(roles, roleOrder);
}
