import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';

import type { UserType } from 'frontend/api/generated';
import { UserPlus } from 'frontend/assets/icons';
import { Button, LoaderSwitch } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';

import { Summary, User, UserRole } from './components';
import styles from './styles.scss';
import { roleIdToFieldName } from './utils';
import type { Role } from '../../types';

const STEPS = Object.freeze({ SELECT_USER: 1, SELECT_ROLES: 2, SUMMARY: 3 });
const SUBSCRIBE = { valid: true, dirty: true };

interface AddMemberModalProps {
  hide(...args: unknown[]): Promise<void>;

  name: string;
  users?: UserType[];
  roles: (Role & { isDefaultRole: boolean })[];
  allPermissions: string[];

  onSubmit(...args: unknown[]): unknown;

  isSubmitting?: boolean;
  disallowOrganizationRoles?: boolean;
  organizationId?: string;
  existingEmails?: string[];
  loading: boolean;
}

const AddMemberModal = ({
  hide,
  name,
  users,
  roles,
  onSubmit: finalSubmit,
  isSubmitting,
  allPermissions,
  existingEmails = [],
  disallowOrganizationRoles,
  organizationId,
  loading,
}: AddMemberModalProps) => {
  const [page, setPage] = useState<number>(STEPS.SELECT_USER);
  const modalTitle = {
    1: `Who are you adding as a member to ${name}`,
    2: 'Which roles should this member have?',
    3: 'Make sure everything looks good before completing!',
  }[page];
  const finalStepAction = users ? 'Add' : 'Send';

  const next = useCallback(() => setPage((current) => current + 1), []);
  const back = useCallback(() => setPage((current) => current - 1), []);
  const onSubmit = useCallback(
    (...args) => {
      if (page === STEPS.SELECT_USER) next();
      if (page === STEPS.SELECT_ROLES) next();
      if (page === STEPS.SUMMARY) finalSubmit(...args);
    },
    [finalSubmit, next, page],
  );

  const initialValues = useMemo(
    () => ({
      email: '',
      selectedUsers: [],
      ...roles.reduce(
        (selectedRoles, { id, isDefaultRole }) => ({ ...selectedRoles, [roleIdToFieldName(id)]: isDefaultRole }),
        {},
      ),
    }),
    [roles],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscribe={SUBSCRIBE}
      render={({ handleSubmit, valid, dirty }) => (
        <LoaderSwitch loading={loading}>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Modal
              hide={hide}
              title={modalTitle}
              icon={UserPlus}
              className={styles.modal}
              footer={false}
              valid={valid}
              disabled={!dirty}
            >
              {page === STEPS.SELECT_USER && (
                <User users={users} existingEmails={existingEmails} organizationId={organizationId} />
              )}
              {page === STEPS.SELECT_ROLES && (
                <UserRole
                  users={users}
                  roles={roles}
                  allPermissions={allPermissions}
                  disallowOrganizationRoles={disallowOrganizationRoles ?? false}
                  organizationId={organizationId}
                />
              )}
              {page === STEPS.SUMMARY && <Summary users={users} name={name} roles={roles} />}
              <Modal.Footer className={styles.footer}>
                <div className={styles.paginationContainer}>
                  <span className={cx(styles.pagination, { [styles.paginationActive]: page >= STEPS.SELECT_USER })} />
                  <span className={cx(styles.pagination, { [styles.paginationActive]: page >= STEPS.SELECT_ROLES })} />
                  <span className={cx(styles.pagination, { [styles.paginationActive]: page >= STEPS.SUMMARY })} />
                </div>
                <div className={styles.actions}>
                  {page > STEPS.SELECT_USER && <Button text="Back" onClick={back} flat className="m-r-2" />}
                  <Button
                    text={page === STEPS.SUMMARY ? finalStepAction : 'Next'}
                    onClick={handleSubmit}
                    isSubmitting={isSubmitting}
                    color={page === STEPS.SUMMARY ? 'primary' : 'secondary'}
                  />
                </div>
              </Modal.Footer>
            </Modal>
          </form>
        </LoaderSwitch>
      )}
    />
  );
};

export default AddMemberModal;
