import cx from 'classnames';

import { numberToEmoji, numberToHappyOrNot } from 'frontend/constants/feedbackFormTypes';
import { filterArrayToObject } from 'frontend/features/Analytics/utils';

import { binaryColors, emojiColors, happyOrNotColors } from './constants';
import styles from '../FeedbackRating/FeedbackRating.scss';

export const asPercentage = (f) => `${(f * 100).toFixed(1)}%`;

export const adjustNoFeedbackRatio = (noFeedback) => (data) => ({
  ...data,
  ratio: noFeedback ? data.ratio * (1 - noFeedback.ratio) : data.ratio,
});

export const filterRatings = (data, ratingFilter) => {
  if (!ratingFilter) return [];
  const exclude = data.filter(({ rating }) => !ratingFilter.includes(rating));
  const totalRatioGone = exclude.reduce((accumulator, { ratio }) => accumulator + ratio, 0);
  const include = data
    .filter(({ rating }) => ratingFilter.includes(rating))
    .map((rating, _, filteredArray) => ({
      ...rating,
      ratio: rating.ratio + totalRatioGone / filteredArray.length,
    }));

  return include;
};

export const mapData = (data, feedbackFilter, sessionData) => {
  const filter = filterArrayToObject(feedbackFilter);

  let { binary, emojis, happy_or_not: happyOrNot = [] } = data;

  if (Object.keys(filter).length) {
    binary = filterRatings(binary, filter.BINARY);
    emojis = filterRatings(emojis, filter.EMOJIS);
    happyOrNot = filterRatings(happyOrNot, filter.HAPPY_OR_NOT);
  }

  const totalEmojis = emojis.reduce((accumulator, { count }) => accumulator + count, 0);
  const totalBinary = binary.reduce((accumulator, { count }) => accumulator + count, 0);
  const totalHappyOrNot = happyOrNot.reduce((accumulator, { count }) => accumulator + count, 0);

  const mappedData = {
    emojiData: {
      total: totalEmojis,
      data: emojis.map(({ rating, ratio }) => ({
        ratio,
        color: emojiColors[rating],
        imageUrl: numberToEmoji[rating].src,
      })),
    },
    binaryData: {
      total: totalBinary,
      data: binary.map(({ rating, ratio }) => ({
        ratio,
        color: binaryColors[rating],
        label: rating === 1 ? 'No' : 'Yes',
        style: cx(styles.badgeRating, { [styles.badgeRatingRed]: rating === 1 }),
      })),
    },
    happyOrNotData: {
      total: totalHappyOrNot,
      data: happyOrNot.map(({ rating, ratio }) => ({
        ratio,
        color: happyOrNotColors[rating],
        imageUrl: numberToHappyOrNot[rating].url,
      })),
    },
  };

  if ((filter.NO_FEEDBACK || Object.keys(filter).length === 0) && sessionData) {
    const { count: totalSessions } = sessionData;
    const totalNotLeftFeedback =
      totalSessions - Object.values(mappedData).reduce((accumulator, { total }) => accumulator + total, 0);

    Object.keys(mappedData).forEach((key) => {
      mappedData[key].noFeedback = {
        total: totalNotLeftFeedback,
        ratio: totalNotLeftFeedback / (totalNotLeftFeedback + mappedData[key].total),
        style: cx(styles.badgeRating, styles.badgeRatingGray),
        label: 'No feedback',
        color: styles.noFeedbackColor,
      };
    });
  }

  return mappedData;
};
