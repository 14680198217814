import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { RoleSettings } from 'frontend/features/Organization/components';
import { CUSTOM_ROLE_NAME, PERMISSION_CATEGORIES } from 'frontend/features/Organization/constants';
import { BOT_PERMISSIONS } from 'frontend/features/Organization/queries';
import { getCustomRoleUpdate } from 'frontend/features/Organization/utils';
import { useToast } from 'frontend/hooks';

import styles from './CustomRoles.scss';
import { UPDATE_CUSTOM_BOT_ROLES } from '../../../mutations';
import { BOT_ROLES } from '../../../queries';

const EMPTY_STATE_TEXT =
  'This workspace has no custom roles. To create one, click the "Create new custom role" button above.';

const VIEW_BOT = 'view_bot';

const VIEW_BOT_DESCRIPTION = Object.values(PERMISSION_CATEGORIES).find((category) => VIEW_BOT in category)[VIEW_BOT];

const validate = ({ customRoles }) =>
  customRoles.some(({ permissions }) => !permissions.includes(VIEW_BOT))
    ? { CustomRoles: `Workspace roles must include the permission "${VIEW_BOT_DESCRIPTION}"` }
    : undefined;

const CustomRoles = () => {
  const toast = useToast();
  const { botId } = useParams();

  const { loading: rolesLoading, data: rolesData } = useQuery(BOT_ROLES, { variables: { botId } });
  const { loading: permissionsLoading, data: permissionsData } = useQuery(BOT_PERMISSIONS);
  const allPermissions = get(permissionsData, 'botPermissions', []);
  const [mutate] = useMutation(UPDATE_CUSTOM_BOT_ROLES);

  const initialValues = {
    customRoles: get(rolesData, 'bot.roles', []).filter(({ name }) => name === CUSTOM_ROLE_NAME),
  };

  const onSubmit = useCallback(
    async (values, form) => {
      const customRoleUpdate = getCustomRoleUpdate(values, form);
      await mutate({ variables: { botId, ...customRoleUpdate } });
      toast.success('Custom roles updated!');
    },
    [botId, mutate, toast],
  );

  return (
    <RoleSettings
      loading={rolesLoading || permissionsLoading}
      initialValues={initialValues}
      allPermissions={allPermissions}
      onSubmit={onSubmit}
      emptyStateText={EMPTY_STATE_TEXT}
      wrapperClassName={styles.wrapper}
      extraValidation={validate}
    >
      <div className={styles.description}>
        When you want to grant users permissions in a workspace on top of those they have in the organization you can
        use workspace roles. If the standard roles are not sufficient you can create your own custom workspace roles
        here.
      </div>
    </RoleSettings>
  );
};

export default CustomRoles;
