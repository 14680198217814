import { get, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useModal } from 'frontend/features/Modals';
import { useSearch, useToggle } from 'frontend/hooks';

import { SORT_ITEMS, USER_SORT_ITEMS } from './constants';
import { AddMemberToOrganization } from '../../modals';

export const useSort = (showPending) => {
  const [sortBy, setSortBy] = useState(SORT_ITEMS.NAME);
  const [reverse, toggleReverse] = useToggle();

  const sortFunction = useCallback((list) => orderBy(list, sortBy, reverse ? 'desc' : 'asc'), [reverse, sortBy]);

  useEffect(() => {
    if (showPending && USER_SORT_ITEMS.includes(sortBy)) setSortBy(SORT_ITEMS.EMAIL);
    else if (!showPending && !USER_SORT_ITEMS.includes(sortBy)) setSortBy(SORT_ITEMS.NAME);
  }, [showPending, sortBy]);

  return { sortBy, setSortBy, reverse, toggleReverse, sortFunction };
};

export const useSortColumn = (sortItem, { sortBy, setSortBy, toggleReverse }) => {
  const isSortedByItem = sortBy === sortItem;

  const onClick = useCallback(() => {
    if (isSortedByItem) toggleReverse();
    else setSortBy(sortItem);
  }, [isSortedByItem, setSortBy, sortItem, toggleReverse]);

  return [isSortedByItem, onClick];
};

export const useOpenInvite = (id, name) => {
  const [showAddMemberModal] = useModal(AddMemberToOrganization);
  const openInvite = useCallback(() => showAddMemberModal({ id, name }), [id, name, showAddMemberModal]);
  return openInvite;
};

const joinText = (objectName, fieldName, item) =>
  get(item, objectName, [])
    .map((object) => get(object, fieldName))
    .join(', ');

const annotateMember = (member) => ({
  ...member,
  email: get(member, 'user.username', ''),
  lastLogin: member.user.lastLogin ? new Date(member.user.lastLogin).getTime() : 0,
  organizationRolesText: joinText('roles', 'title', member.membership),
  name: get(member, 'user.profile.fullName', member.user.username),
  status: get(member, 'membership.agentProfile.availability', ''),
});

const annotateInvitation = (invitation) => ({
  ...invitation,
  organizationRolesText: joinText('organizationRoles', 'title', invitation),
  botRolesText: joinText('botRoles', 'title', invitation),
});

const getSearchKeys = (invitations) =>
  invitations ? ['email', 'organizationRolesText', 'botRolesText'] : ['email', 'name', 'organizationRolesText'];

export const useFilteredList = (list, { filter, sortFunction, invitations }) => {
  const annotated = useMemo(() => list.map(invitations ? annotateInvitation : annotateMember), [invitations, list]);
  const searchKeys = useMemo(() => getSearchKeys(invitations), [invitations]);
  const { searchResults, setQuery } = useSearch(annotated, searchKeys);
  const sorted = useMemo(() => sortFunction(searchResults), [searchResults, sortFunction]);

  useEffect(() => {
    setQuery(filter);
  }, [filter, setQuery]);

  return sorted;
};
