import { get, isEqual, orderBy } from 'lodash';
import { useCallback } from 'react';
import { useForm, useFormState } from 'react-final-form';

import Unchecked from './Unchecked';
import type { Role } from '../../types';
import CheckMark from '../CheckMark';

const permissionsAreEquivalent = (permissions1, permissions2) => isEqual(orderBy(permissions1), orderBy(permissions2));

// If a role contains its initial permissions, use the initial order so that the form state appears unchanged
const orderRolePermissions = (form, role, permissions) => {
  const { initialValues } = form.getState();
  const initialRole = get(initialValues, 'customRoles', []).find(({ id }) => id === role.id);

  if (!initialRole) return permissions;
  if (permissionsAreEquivalent(permissions, initialRole.permissions)) return initialRole.permissions;
  return permissions;
};

interface RolePermissionItemProps {
  hasPermission: boolean;
  role?: Role;
  permission?: string;
}

const RolePermissionItem = ({ hasPermission, role, permission }: RolePermissionItemProps) => {
  const form = useForm();
  const { values } = useFormState();

  const onClick = useCallback(() => {
    const customRoles = values?.customRoles ?? [];
    const updatedRolePermissions = hasPermission
      ? (role?.permissions || []).filter((rolePermission) => rolePermission !== permission)
      : [...(role?.permissions || []), permission];

    const updatedRole = { ...role, permissions: orderRolePermissions(form, role, updatedRolePermissions) };
    const updatedCustomRoles = customRoles.map((roleItem) => (roleItem.id === updatedRole.id ? updatedRole : roleItem));

    form.change('customRoles', updatedCustomRoles);
  }, [form, hasPermission, permission, role, values]);

  return hasPermission ? <CheckMark onClick={onClick} /> : <Unchecked onClick={onClick} />;
};

export default RolePermissionItem;
