import { useCallback } from 'react';
import { useFormState } from 'react-final-form';

import { Button } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { useBotOrSkill } from 'frontend/hooks';

import styles from './styles.scss';
import { UpdateFeedbackForm } from '../../modals';

const CustomizeFeedbackButton = () => {
  const botOrSkillParams = useBotOrSkill();
  const [showFeedbackFormModal] = useModal(UpdateFeedbackForm);

  const {
    values: { feedbackFormType },
  } = useFormState();

  const openModal = useCallback(
    () => showFeedbackFormModal({ feedbackFormType, botOrSkillParams }),
    [botOrSkillParams, feedbackFormType, showFeedbackFormModal],
  );

  return (
    <Button size="small" className={styles.feedbackFormCustomizeButton} onClick={openModal}>
      Customize feedback form
    </Button>
  );
};

export default CustomizeFeedbackButton;
