import PropTypes from 'prop-types';

import { Table } from 'frontend/components';

import styles from './Backups.scss';
import { BackupOptions } from '..';

const standardColumns = [{ title: 'ID', key: 'id' }, { key: 'created' }, { key: 'status' }];
const optionColumn = { title: '', key: 'options', component: BackupOptions };

const Backups = ({ backups, extraColumns = [] }) => {
  const columns = [...standardColumns, ...extraColumns, optionColumn];

  return (
    <div className={styles.container}>
      <h2> Backups list</h2>
      <Table data={backups} columns={columns} className={styles.table} bordered />
    </div>
  );
};

Backups.propTypes = {
  backups: PropTypes.arrayOf(PropTypes.shape({})),
  extraColumns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      component: PropTypes.element,
    }),
  ),
};

export default Backups;
