import cx from 'classnames';

import type { ChatFeedbackType } from 'frontend/api/generated';
import { TextCapitalize } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { sentimentIcons } from 'frontend/constants/feedbackFormTypes';

import styles from './FeedbackRating.scss';

const FeedbackRating = ({
  data: { sentiment, freeformText },
  className,
}: {
  data: ChatFeedbackType;
  className?: string;
}) => {
  const renderFeedback = () => {
    const { description, src } = sentimentIcons[sentiment!];
    return (
      <img
        title={description}
        src={src}
        alt={description}
        aria-label={description}
        className={styles.happyOrNotRating}
      />
    );
  };

  return (
    <div className={cx(styles.feedbackWrapper, className)}>
      {renderFeedback()}
      {freeformText && <Icon color="darkGray" component={TextCapitalize} width={18} height={18} />}
    </div>
  );
};

export default FeedbackRating;
