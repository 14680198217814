import React from 'react';

import type { ChatFeedbackType, ImageButtonType } from 'frontend/api/generated';
import { ChatBubble, SplitChatBubble } from 'frontend/components';
import ImageCarouselPreview from 'frontend/components/ImageCarouselPreview/ImageCarouselPreview';
import { camelCaseKeys } from 'frontend/utils';

import styles from './MessageReply.scss';
import { SENDER_TYPES } from '../../utils/constants';
import isButtonClick from '../../utils/isButtonClick';
import type { CompleteChatMessageType } from '../../utils/types';
import ChatButton from '../ChatButton/ChatButton';
import Feedback from '../Feedback/Feedback';

interface MessageReplyProps {
  message: CompleteChatMessageType;
}

const MessageReply = ({ message }: MessageReplyProps) => {
  if (isButtonClick(message)) {
    if (message.button.type === 'imageCarousel' && message.button.imageCarousel?.length) {
      return (
        <ImageCarouselPreview
          isButtonClick
          clickedButtonId={message.button.id}
          imageCarousel={{
            images: message.button.imageCarousel.map((image) => ({
              ...image,
              buttons: image.buttons?.map((btn) => camelCaseKeys(btn)) as ImageButtonType[],
            })),
            size: message.button.imageCarouselSize as number,
          }}
        />
      );
    }
    return (
      <ChatButton
        key={message.button.id}
        label={message.button.label as string}
        buttonType={camelCaseKeys(message.button).buttonType}
        title={message.button.label as string}
        isButtonClick
      />
    );
  }

  if (message.__typename === 'ChatFeedbackType') {
    return <Feedback feedback={message as unknown as ChatFeedbackType} />;
  }

  if (!message.message) {
    return null;
  }

  if (message.sender === SENDER_TYPES.BOT) {
    return (
      <SplitChatBubble
        className={styles.insightsChatbubble}
        text={message.message}
        hasImage={!!message.imageCarousel}
      />
    );
  }

  return (
    <ChatBubble
      className={styles.insightsChatbubble}
      isFromAgent={message.sender === SENDER_TYPES.AGENT}
      isFromUser={message.sender === SENDER_TYPES.USER}
    >
      {message.message}
    </ChatBubble>
  );
};

export default MessageReply;
