import { isEqual } from 'lodash';
import { Field, Form } from 'react-final-form';

import { Condition, FormErrors, Loader, PageBar, Panel } from 'frontend/components';
import { HANDOVER_MODE } from 'frontend/features/Inbox/constants';

import { useHandoverForm } from './useHandoverForm';
import BusinessHours from '../components/BusinessHours';
import Cleanup from '../components/Cleanup';
import ExternalHandover from '../components/ExternalHandover';
import HandoverMode from '../components/HandoverMode';
import Messages from '../components/Messages';
import Workflow from '../components/Workflow';
import businessHours from '../validators/businessHours';

export const Handover = () => {
  const { botId, onSubmit, loading, initialValues } = useHandoverForm();

  if (loading) return <Loader size="large" />;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      validate={businessHours}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <PageBar>
            <PageBar.FormButtons />
          </PageBar>
          <Panel>
            <FormErrors />
            <Field component={HandoverMode} name="mode" readonly />
            <Condition when="mode" isNot={HANDOVER_MODE.DISABLED.value}>
              <>
                <Panel.Separator />
                <Condition when="mode" is={HANDOVER_MODE.EXTERNAL.value}>
                  <>
                    <ExternalHandover data={initialValues} />
                    <Panel.Separator />
                  </>
                </Condition>
                <BusinessHours />
                <Panel.Separator />
                <Workflow />
                <Panel.Separator />
                <Messages botId={botId} />
                <Panel.Separator />
                <Cleanup />
              </>
            </Condition>
          </Panel>
        </form>
      )}
    />
  );
};
