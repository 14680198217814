import React from 'react';

import type { ChatFeedbackType } from 'frontend/api/generated';

import styles from './Feedback.scss';
import FeedbackRating from '../FeedbackRating/FeedbackRating';
import Timestamp from '../Timestamp/Timestamp';

const Feedback = ({ feedback }: { feedback?: ChatFeedbackType }) => {
  if (!feedback) return null;
  return (
    <div className={styles.feedbackWrapper}>
      <div className={styles.feedbackContainer}>
        Feedback: <FeedbackRating className={styles.feedbackContent} data={feedback} />{' '}
        {feedback.freeformText && <div className={styles.feedbackContent}>{feedback.freeformText}</div>}
      </div>
      <Timestamp timestamp={feedback.createdAt} />
    </div>
  );
};

export default Feedback;
