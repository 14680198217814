import { gql } from '@apollo/client';

import ORGANIZATION_ROLE_FRAGMENT from 'frontend/features/Organization/fragments/OrganizationRole.fragment.graphql';
import ORGANIZATION_USER from 'frontend/features/Organization/fragments/OrganizationUser.fragment.graphql';

export default gql`
  query AddOrganizationMembership($id: ID!) {
    organization(id: $id) {
      id
      users {
        ...OrganizationUser
      }
      roles {
        ...OrganizationRole
        isDefaultRole
      }
    }
  }
  ${ORGANIZATION_USER}
  ${ORGANIZATION_ROLE_FRAGMENT}
`;
