import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { getSkillSettings } from 'frontend/api/queries';
import { FormErrors, Loader, Panel } from 'frontend/components';
import { useTabTitle, useToast } from 'frontend/hooks';

import SettingsPageBar from '../components/SettingsPageBar';
import SkillFields from '../components/SkillFields';
import UPDATE_SKILL_SETTINGS from '../mutations/updateSkillSettings';

const General = () => {
  const toast = useToast();
  const { skillId } = useParams();
  const { skillData } = useTabTitle('General settings');
  const { data, loading } = useQuery(getSkillSettings, { variables: { skillId } });

  const [mutate] = useMutation(UPDATE_SKILL_SETTINGS);
  const onSubmit = useCallback(
    async (values) => {
      await mutate({
        variables: {
          skillId,
          skillInput: {
            ...omit(values, ['__typename', 'permissions', 'languages']),
          },
        },
      });
      toast.success('Settings updated!');
    },
    [mutate, skillId, toast],
  );

  if (loading || !skillData) {
    return <Loader size="large" />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data.skill}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <SettingsPageBar skill={skillData.skill} />
          <FormErrors />
          <Panel>
            <SkillFields />
          </Panel>
        </form>
      )}
    />
  );
};

export default General;
