import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { InboxStatusDocument } from 'frontend/api/generated';
import { Pagination } from 'frontend/components';
import { usePagination } from 'frontend/hooks';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { IDType } from 'frontend/propTypes';

import Member from './Member';
import styles from './styles.scss';
import { MemberType } from '../../../../propTypes';
import { SORT_ITEMS } from '../../constants';
import { useSortColumn } from '../../hooks';
import { SortType } from '../../propTypes';
import SortIcon from '../SortIcon';

const PAGE_SIZE = 15;

const UsersList = ({ members, sort, sort: { reverse }, botId, organizationId, isInheritedFromOrg }) => {
  const [isSortedByName, onClickName] = useSortColumn(SORT_ITEMS.NAME, sort);
  const [isSortedByStatus, onClickStatus] = useSortColumn(SORT_ITEMS.STATUS, sort);
  const [isSortedByUsername, onClickUsername] = useSortColumn(SORT_ITEMS.USERNAME, sort);
  const [isSortedByOrganizationRoles, onClickOrganizationRoles] = useSortColumn(SORT_ITEMS.ORGANIZATION_ROLES, sort);
  const [isSortedByLastLogin, onClickLastLogin] = useSortColumn(SORT_ITEMS.LAST_LOGIN, sort);

  const { organizationId: isOrgInbox } = useParams();

  const [currentMembers, page, pages, setPage] = usePagination({
    objects: members,
    pageSize: PAGE_SIZE,
  });

  const { data: statusData } = useQuery(InboxStatusDocument, {
    variables: { botId },
    skip: !botId,
  });
  const agentsHandoverIds = statusData?.inboxStatus?.agents.map((agent) => agent.membership.user.id) || [];

  const { organizationPermissions, botPermissions } = useMyPermissions({ organizationId, botId });

  const showActions = botId
    ? botPermissions.includes('manage_bot')
    : organizationPermissions.includes('invite_members');

  return (
    <div role="table" className={styles.userList}>
      <div role="row" className={styles.userHeader}>
        <div role="columnheader">
          <button className={styles.sortButton} onClick={onClickName} type="button">
            Name
            {isSortedByName && <SortIcon sort="name" reverse={reverse} />}
          </button>
        </div>
        <div role="columnheader">
          <button className={styles.sortButton} onClick={onClickStatus} type="button">
            Status
            {isSortedByStatus && <SortIcon sort="status" reverse={reverse} />}
          </button>
        </div>
        <div role="columnheader">
          <button className={styles.sortButton} onClick={onClickUsername} type="button">
            Username
            {isSortedByUsername && <SortIcon sort="email" reverse={reverse} />}
          </button>
        </div>
        <div role="columnheader">
          <button className={styles.sortButton} onClick={onClickOrganizationRoles} type="button">
            {botId ? 'Workspace roles' : 'Organization roles'}
            {isSortedByOrganizationRoles && <SortIcon sort="roles" reverse={reverse} />}
          </button>
        </div>
        <div role="columnheader">
          <button className={styles.sortButton} onClick={onClickLastLogin} type="button">
            Last logged in
            {isSortedByLastLogin && <SortIcon sort="last logged in" reverse={reverse} />}
          </button>
        </div>
        <div role="columnheader" />
      </div>
      <div className={styles.usersContent}>
        {currentMembers.map(({ user, membership }) => {
          const isHandoverAgent = agentsHandoverIds.includes(user.id);
          const shouldShowStatus = Boolean(isHandoverAgent || isOrgInbox);

          return (
            <Member
              key={`user-${user.id}`}
              user={user}
              organizationId={organizationId}
              membership={membership}
              showOptions={showActions}
              shouldShowStatus={shouldShowStatus}
              botId={botId}
              isInheritedFromOrg={isInheritedFromOrg}
            />
          );
        })}
        <div className={styles.pagination}>
          <Pagination currentPage={page} pages={pages} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

UsersList.propTypes = {
  members: PropTypes.arrayOf(MemberType).isRequired,
  botId: IDType,
  organizationId: IDType,
  sort: SortType.isRequired,
  isInheritedFromOrg: PropTypes.bool,
};

export default UsersList;
