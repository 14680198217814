import cx from 'classnames';

import { ChatBubble } from 'frontend/components';

import styles from './SplitChatBubble.scss';

/**
 * Split the HTML string on three consecutive <br> tags.
 * @param htmlString The HTML string to split.
 * @returns An array of html strings. */
function splitHTMLStringOnThreeBRs(htmlString: string): string[] {
  const uniqueDelimiter = '%%%SPLIT_HERE%%%';

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const brs = Array.from(doc.body.querySelectorAll('br'));
  if (brs.length < 3) return [htmlString];

  brs.forEach((br, i) => {
    if (br.nextSibling === brs[i + 1] && brs[i + 1]?.nextSibling === brs[i + 2]) {
      br.parentNode?.insertBefore(doc.createTextNode(uniqueDelimiter), br);
      br.remove();
      brs[i + 1]?.remove();
      brs[i + 2]?.remove();
    }
  });

  const splitContent = doc.body.innerHTML.split(uniqueDelimiter);
  return splitContent;
}

interface SplitChatBubbleProps {
  /** Text content of the chat. */
  text: string;
  /** Whether the text contains an image. */
  hasImage: boolean;
  className?: string;
}

/**  Splits into several chatbubbles on specific newlines. */
export default function SplitChatBubble({ text, hasImage, className }: SplitChatBubbleProps) {
  const dividedText = splitHTMLStringOnThreeBRs(text) // Split the message on three consecutive <br> (Lexical)
    .flatMap((msg: string) => msg.split(/\n\s*\n/)) // Split the message on two consecutive newlines (DraftJS)
    .map((msg: string) => msg.trim()) // Trim the messages
    .filter((msg: string) => msg !== '');

  return (
    <>
      {dividedText.map((textSegment, idx) => (
        <ChatBubble
          // eslint-disable-next-line react/no-array-index-key
          key={`chatbubble-segment-${idx}`}
          className={cx(styles.splitChatubble, className)}
          hasImage={hasImage}
        >
          {textSegment}
        </ChatBubble>
      ))}
    </>
  );
}
