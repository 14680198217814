import { isEqual, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Form } from 'react-final-form';

import { FormErrors, GuardUnsaved, LoaderSwitch, PageBar, Panel } from 'frontend/components';
import { ChildrenType } from 'frontend/propTypes';

import CustomRoleSettings from './CustomRoleSettings';
import { PermissionsType } from '../../propTypes';

const subscribe = {};

const getAllPairs = (array) => array.map((item1, idx) => array.slice(idx + 1).map((item2) => [item1, item2])).flat();

const permissionsEqual = ([{ permissions: permissions1 }, { permissions: permissions2 }]) =>
  isEqual(orderBy(permissions1), orderBy(permissions2));

const getValidator = (extraValidation) => (values) => {
  const allPairs = getAllPairs(values.customRoles);
  if (allPairs.some(permissionsEqual)) return { CustomRoles: 'Two custom roles cannot have identical permissions' };

  return extraValidation(values);
};

const RoleSettings = ({
  loading,
  onSubmit,
  initialValues,
  allPermissions,
  emptyStateText,
  wrapperClassName,
  children,
  extraValidation = () => undefined,
}) => {
  const validate = useMemo(() => getValidator(extraValidation), [extraValidation]);

  return (
    <LoaderSwitch loading={loading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscribe={subscribe}
        validate={validate}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors />
              <div className={wrapperClassName}>
                <Panel>
                  <CustomRoleSettings allPermissions={allPermissions} emptyStateText={emptyStateText}>
                    {children}
                  </CustomRoleSettings>
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
};

RoleSettings.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  allPermissions: PermissionsType.isRequired,
  emptyStateText: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  children: ChildrenType,
  extraValidation: PropTypes.func,
};

export default RoleSettings;
