import { useId, useRef } from 'react';
import type { ControllerRenderProps, UseFormReturn } from 'react-hook-form';

import { Edit, Reset, UploadCloud } from 'frontend/assets/icons';
import { Dropdown, Icon } from 'frontend/components';

import styles from './EmojiUploaderRHF.scss';
import type { CustomerFeedbackInputs } from '../CustomerFeedback';
import { type RHF_FORMS_FEEDBACK_PATH, getDefaultLabelImage } from '../constants';

const MAX_FILE_SIZE_KB = 100;
const MAX_FILE_SIZE = MAX_FILE_SIZE_KB * 1024;

interface Props {
  field: ControllerRenderProps<
    CustomerFeedbackInputs,
    `${RHF_FORMS_FEEDBACK_PATH}.ratingScale.editLabels.${number}.image`
  >;
  rhfMethods: UseFormReturn<CustomerFeedbackInputs>;
  rhfFormPath: RHF_FORMS_FEEDBACK_PATH;
  index: number;
}

export default function EmojiUploaderRHF({ field, rhfMethods, rhfFormPath, index }: Props): React.JSX.Element {
  const { getValues } = rhfMethods;

  const inputId = useId();
  const inputRef = useRef<HTMLInputElement>(null);

  const overlayOptions = [
    {
      icon: UploadCloud,
      text: 'Upload image',
      onClick: () => {
        inputRef.current?.click();
      },
    },
    {
      icon: Reset,
      text: 'Reset to default',
      onClick: () => {
        const ratingSystem = getValues(`${rhfFormPath}.ratingScale.ratingSystem`);
        const defaultImage = getDefaultLabelImage(ratingSystem, index);
        field.onChange(defaultImage);
      },
      color: 'red' as const,
    },
  ];

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    rhfMethods.clearErrors(field.name);

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        rhfMethods.setError(field.name, {
          type: 'manual',
          message: `Impossible to upload: image size exceeded ${MAX_FILE_SIZE_KB}KB limit`,
        });
        return;
      }

      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          field.onChange(base64String);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        rhfMethods.setError(field.name, {
          type: 'manual',
          message: 'Error reading file, try again',
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      <Dropdown position="bottom-left" overlay={<Dropdown.MenuOverlay options={overlayOptions} />}>
        <label htmlFor={inputId} className="all-unset">
          <figure className="all-unset">
            <img src={field.value} alt="" className={styles.image} />
            <figcaption className="visually-hidden">Choose a new emoji image</figcaption>
          </figure>
          <div className={styles.overlay}>
            <Icon component={Edit} />
          </div>
        </label>
      </Dropdown>
      <input
        ref={inputRef}
        type="file"
        id={inputId}
        onChange={handleImageUpload}
        accept="image/*"
        className="visually-hidden"
      />
    </div>
  );
}
