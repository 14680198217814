import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

export default PropTypes.exact({
  id: IDType.isRequired,
  planName: PropTypes.string,
  isActive: PropTypes.bool,
  startedAt: PropTypes.string,
  endedAt: PropTypes.string,
  __typename: PropTypes.oneOf(['SubscriptionType']),
});
