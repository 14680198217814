import { usePusher } from '@harelpls/use-pusher';
import { useCallback, useEffect, useState } from 'react';

/*
All connection states: https://pusher.com/docs/channels/using_channels/connection#available-states

We don't need to differentiate between failed and unavailable.

Note that 'disconnected' indicates voluntary disconnect and is not a fail state.

Store connection state in local state rather than listening to 'state_change' because when the
latter goes to or from 'connecting' we don't know if it originates from a fail state
*/

// const STATE = Object.freeze({ CONNECTED: 'connected', CONNECTING: 'connecting', FAILED: 'failed' });
const STATE = ['CONNECTED', 'CONNECTING', 'FAILED'];

export default (onFailed, onConnecting, onReconnected) => {
  const { client } = usePusher();
  const [connectionState, setConnectionState] = useState<(typeof STATE)[number]>('CONNECTED');

  const handleFailed = useCallback(
    (...args) => {
      if (connectionState === 'FAILED') return;
      onFailed(...args);
      setConnectionState('FAILED');
    },
    [connectionState, onFailed],
  );

  const handleConnecting = useCallback(
    (...args) => {
      if (connectionState !== 'FAILED') return;
      onConnecting?.(...args);
      setConnectionState('CONNECTING');
    },
    [connectionState, onConnecting],
  );

  const handleConnected = useCallback(
    (...args) => {
      if (connectionState === 'CONNECTED') return;
      onReconnected?.(...args);
      setConnectionState('CONNECTED');
    },
    [connectionState, onReconnected],
  );

  useEffect(() => {
    if (!client?.connection?.bind) return undefined;

    client.connection.bind('failed', handleFailed);
    client.connection.bind('unavailable', handleFailed);
    client.connection.bind('connecting', handleConnecting);
    client.connection.bind('connected', handleConnected);

    return () => {
      client.connection.unbind('failed', handleFailed);
      client.connection.unbind('unavailable', handleFailed);
      client.connection.unbind('connecting', handleConnecting);
      client.connection.unbind('connected', handleConnected);
    };
  }, [client?.connection, handleConnected, handleConnecting, handleFailed]);
};
