import { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { Loader } from 'frontend/components';
import { ChatClient, NotFound, RecoverBot, Train } from 'frontend/features';
import { BotDashboard } from 'frontend/features/BotDashboard/views';
import Insights from 'frontend/features/Insights/Insights';
import { PusherMultiple, getPusherChannel } from 'frontend/features/Pusher';
import { useInitialiseLanguage, useTabTitle } from 'frontend/hooks';
import useSentryIntegrations from 'frontend/hooks/useSentryIntegrations';
import { connectAnalytics, disconnectAnalytics } from 'frontend/state/dux/analytics/dashboard';
import { setCurrentBot } from 'frontend/state/dux/navbar';
import { useAppDispatch } from 'frontend/state/hooks';

import AnalyticsRoutes from './AnalyticsRoutes';
import BuildRoutes from './BuildRoutes';
import ConnectRoutes from './ConnectRoutes';
import InboxChatRoutes from './InboxChatRoutes';
import SettingsRoutes from './SettingsRoutes';
import { useBotEvents, useBotLikeEvents } from './hooks';

const BotRoutes = () => {
  const dispatch = useAppDispatch();
  const { botId } = useParams();
  const { loading, botData } = useTabTitle('');
  const bot = botData?.bot;

  const botEvents = useBotEvents(bot);
  const botLikeEvents = useBotLikeEvents(bot);

  useInitialiseLanguage();
  useSentryIntegrations(botId);

  useEffect(() => {
    if (!bot) return undefined;

    dispatch(connectAnalytics(botId));
    return () => {
      dispatch(disconnectAnalytics());
    };
  }, [bot, botId, dispatch]);

  useEffect(() => {
    if (!bot) return;
    dispatch(setCurrentBot(bot));
  }, [bot, dispatch]);

  if (loading) return <Loader size="large" />;

  if (!botId || !bot) return <NotFound text="Workspace not found or missing permissions" />;

  if (bot.archivedAt) {
    return <RecoverBot botId={bot.id} name={bot.name} archivedAt={bot.archivedAt} organization={bot.organization} />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="dashboard" replace />} />
        <Route path="/analytics/*" element={<AnalyticsRoutes />} />
        <Route path="/dashboard" element={<BotDashboard />} />
        <Route path="/build/*" element={<BuildRoutes />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/insights/:chatId" element={<Insights />} />
        <Route path="/inbox/*" element={<InboxChatRoutes />} />
        <Route path="/train" element={<Train />} />
        <Route path="/connect/*" element={<ConnectRoutes />} />
        <Route path="/settings/*" element={<SettingsRoutes />} />
        <Route element={<NotFound />} />
      </Routes>
      <ChatClient botId={botId} />
      <PusherMultiple channel={getPusherChannel({ botId })} events={botEvents} />
      <PusherMultiple channel={getPusherChannel({ botId })} events={botLikeEvents} />
    </>
  );
};

export default BotRoutes;
