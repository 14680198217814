import { withErrorHandler } from 'frontend/enhancers';
import { AnalyticsContent, Grid, Heading } from 'frontend/features/Analytics/components';
import { useFilters } from 'frontend/features/Analytics/hooks';
import { useBooleanState } from 'frontend/hooks';

import { FeedbackSummary, UserFeedbackTable } from './components';
import styles from './styles.scss';
import Filters, { FilterInfo } from '../Filters';

const HIDE_FILTERS = {
  granularity: true,
  language: true,
  sources: true,
  nudges: true,
  engagementCampaigns: true,
  sessionTypes: true,
};
const SKIP = Object.keys(HIDE_FILTERS);

const FeedbackOverview = () => {
  const [filterViewShowing, showFilterView, hideFilterView] = useBooleanState();
  const filters = useFilters({ skip: SKIP });

  return (
    <>
      <Filters hideFilterView={hideFilterView} filterViewShowing={filterViewShowing} hideFilter={HIDE_FILTERS} />
      <AnalyticsContent>
        <FilterInfo showFilterView={showFilterView} hideFilter={HIDE_FILTERS} />
        <div className="m-b-4">
          <Grid columns={1}>
            <div>
              <FeedbackSummary filters={filters} className={styles.wrapper} />
            </div>
          </Grid>
          <Grid columns={1}>
            <div>
              <Heading title="User feedback" />
              <UserFeedbackTable filters={filters} />
            </div>
          </Grid>
        </div>
      </AnalyticsContent>
    </>
  );
};

export default withErrorHandler(FeedbackOverview);
