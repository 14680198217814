/* eslint-disable no-use-before-define */
import cx from 'classnames';
import { get, isNil } from 'lodash';
import { Fragment } from 'react';
import { useFormState } from 'react-final-form';
import { useLocation, useSearchParams } from 'react-router-dom';

import type {
  BuildFormTextType,
  BuildFormType,
  ButtonsDisplayOrientation,
  FormFieldTextType,
} from 'frontend/api/generated';
import { AnnotationWarning, ChevronDown, ChevronRight, Plus, UnorderedList } from 'frontend/assets/icons';
import { Button, ChatBubble, Dropdown, Icon, Input, SplitChatBubble } from 'frontend/components';
import ImageCarouselPreview from 'frontend/components/ImageCarouselPreview/ImageCarouselPreview';
import MessageClickable from 'frontend/components/MessageClickable/MessageClickable';
import VideoEmbedPreview from 'frontend/components/VideoEmbedPreview/VideoEmbedPreview';
import { DIALOGUE_TYPES } from 'frontend/constants';
import { insertEntityNames } from 'frontend/features/Entities/utils';
import { getBuildUrl } from 'frontend/features/Library/utils';
import { useBotOrSkill, useCurrentLanguage } from 'frontend/hooks';

import PluginDialogue from './PluginDialogue';
import styles from './Preview.scss';
import { useBuildItems } from './hooks';
import { DEFAULT_RULE_ID } from '../../constants';
import { useDialogueType } from '../../hooks';
import useCurrentRuleId from '../../hooks/useCurrentRuleId';
import type { TabName, TabsShown } from '../../hooks/useDialogueOutputs';
import { handleEditorState } from '../../utils';
import ChatbubbleButtons from '../ChatbubbleButtons';
import inputTypes from '../Forms/utils/constants';
import { findFieldValidator, findFormByLanguageAndRule } from '../Forms/utils/helpers';
import OutputOverlay from '../VerticalToolbar/ButtonOverlays/OutputOverlay';

const hasVideo = (values, currentLanguage, videoName, currentRuleId) =>
  Boolean(values[videoName]?.[currentLanguage]?.[currentRuleId]);

const formatSlotName = (name: string): string => `[${name.toUpperCase()}]`;

const getReplyText = (reply: Record<string, unknown> = {}, isIntentDialogue: boolean) =>
  isIntentDialogue
    ? reply.text
    : insertEntityNames(handleEditorState(reply), { modifyName: formatSlotName }).searchText;

export const renderFileText = (label: string = '', slug: string) => {
  const regex = /\[(.*?)\]/;
  // In odd positions of the "parts" will always be what's between brackets.
  const parts = label.split(regex);
  return parts.map((part, index) => (
    <Fragment key={`file_text_${part}_${slug}`}>
      {index % 2 !== 0 && <>&nbsp;</>}
      <span className={cx({ [styles.fileUploadText]: index % 2 !== 0 })} key={`file-text-${part}`}>
        {part}
      </span>
    </Fragment>
  ));
};

type EmptyPreviewProps = {
  isFlowBuilder?: Props['isFlowBuilder'];
  dialogueUrl?: string;
  tabsShownMap: Props['tabsShownMap'];
  setTabsToShowExtra: Props['setTabsToShowExtra'];
};

const EmptyPreview = ({ isFlowBuilder, dialogueUrl, tabsShownMap, setTabsToShowExtra }: EmptyPreviewProps) => (
  <div className={styles.emptyState}>
    <div className={styles.emptyStateIconWrapper}>
      <Icon component={AnnotationWarning} />
    </div>
    <h4 className={styles.emptyStateTitle}>Create a nice bot answer</h4>
    <p className={styles.emptyStateDescription}>
      This dialogue contains no bot answer for this language yet. Create an answer that suits this dialogue context.
    </p>
    {isFlowBuilder && <Button to={dialogueUrl} text="Go to dialogue" />}
    {!isFlowBuilder && (
      <Dropdown
        position="bottom"
        triggerClassName={styles.emptyStateButton}
        overlay={<OutputOverlay tabsShownMap={tabsShownMap} setTabsToShowExtra={setTabsToShowExtra} isModDialogue />}
      >
        <Icon component={Plus} />
        Add bot answer
      </Dropdown>
    )}
  </div>
);

const renderInput = (field) => {
  const { label, placeholderText, affixValue } = field.texts as FormFieldTextType;

  const maximum = findFieldValidator(field.validators, 'maximum')?.maximum;
  const minimum = findFieldValidator(field.validators, 'minimum')?.minimum;

  switch (field.inputType) {
    case inputTypes.CHECKBOX:
    case inputTypes.RADIO:
      return (
        <div className={styles.formPreviewCheckbox}>
          <div className={styles.formPreviewInputLabelWrapper}>
            {label} {field?.required && <div className={styles.formPreviewInputRequired}>&#42;</div>}
          </div>
          {field.attributes?.options.map((option, index) => (
            <label
              className={styles.previewCheckboxWrapper}
              // eslint-disable-next-line react/no-array-index-key
              key={`${field.id}_checkbox_${index}`}
              htmlFor={`${field.id}_checkbox_${index}`}
            >
              <Input
                id={`${field.id}_checkbox_${index}`}
                input={{
                  name: `${field.id}_checkbox_${index}`,
                }}
                className={styles.formPreviewInput}
                disabled
                inputType={field.inputType}
              />
              <div className={styles.previewCheckboxLabel}>{option?.label}</div>
            </label>
          ))}
        </div>
      );

    case inputTypes.FILE:
      return (
        <>
          <Input
            className={styles.fileInputPreviewInput}
            disabled
            label={
              <div className={styles.formPreviewInputLabelWrapper}>
                {label} {field?.required && <div className={styles.formPreviewInputRequired}>&#42;</div>}
              </div>
            }
            input={{
              name: `field_${field.inputType}_${field.id}`,
            }}
            inputType={field.inputType}
          />
          <div className={styles.fileInputPreview}>{renderFileText(placeholderText, field.slug)}</div>
        </>
      );
    default:
      return (
        <Input
          input={{
            name: `field_${field.inputType}_${field.id}`,
            value: !isNil(field.attributes?.defaultValue) ? field.attributes?.defaultValue : '',
          }}
          minRows={field.inputType === inputTypes.TEXTAREA ? 2 : undefined}
          className={styles.formPreviewInput}
          disabled
          multiline={field.inputType === inputTypes.TEXTAREA}
          inputType={field.inputType}
          adornment={
            <>
              {field.inputType !== inputTypes.RANGE && field.affix && !!affixValue && <div>{affixValue}</div>}
              {field.inputType === inputTypes.SELECT && <Icon component={ChevronDown} />}
            </>
          }
          adornmentPosition={field.inputType !== inputTypes.RANGE && field?.affix === 'PREFIX' ? 'left' : 'right'}
          label={
            <>
              <div className={styles.formPreviewInputLabelWrapper}>
                {label} {field?.required && <div className={styles.formPreviewInputRequired}>&#42;</div>}
              </div>
              {field.inputType === inputTypes.RANGE && field.affix && !!affixValue && (
                <div className={styles.sliderAffixWrapper}>
                  {field.affix === 'PREFIX' && <div className={styles.affix}>{affixValue}</div>}
                  <div className={styles.affixPreviewValue}>{field.attributes?.defaultValue || 0}</div>
                  {field.affix === 'SUFFIX' && <div className={styles.affix}>{affixValue}</div>}
                </div>
              )}
            </>
          }
          min={minimum}
          max={maximum}
          placeholder={placeholderText}
        />
      );
  }
};

const FormPreview = ({ form }: { form: BuildFormType }) => {
  const { title, submitButtonText, cancelButtonText } = form.texts as BuildFormTextType;

  return (
    <div className={styles.formPreviewWrapper}>
      <div className={styles.formPreviewContainer}>
        {!!title && (
          <div className={styles.formPreviewTitle}>
            <Icon component={UnorderedList} />
            {title}
          </div>
        )}
        <div className={styles.formPreviewContent}>
          {form.fields?.map((field) => {
            const { helpText } = field.texts as FormFieldTextType;

            const maxLength = findFieldValidator(field.validators, 'maxLength')?.maxLength;
            return (
              <Fragment key={`form_preview_field_${field.id}`}>
                <div className={styles.formPreviewInputContainer}>
                  {renderInput(field)}
                  {!!maxLength && <div className={styles.formPreviewMaxLength}>{`0 / ${maxLength}`}</div>}
                </div>
                {!!helpText && <div className={styles.formPreviewHelperText}>{helpText}</div>}
              </Fragment>
            );
          })}
        </div>
        <div className={styles.formPreviewActions}>
          <Button className={styles.formPreviewBtnCancel} disabled color="white" text={cancelButtonText || 'Exit'} />
          <Button
            className={styles.formPreviewBtnSubmit}
            disabled
            color="gray"
            iconPosition="right"
            text={submitButtonText || 'Send'}
            icon={ChevronRight}
          />
        </div>
      </div>
    </div>
  );
};

interface Props {
  className?: string;
  /** Dialogue data that gets passed when in Flow Viewer. */
  dialogue?: {
    id: string;
    dialogueType: string;
    buildForms: BuildFormType[];
    intent?: string;
    buttonsDisplayOrientation: ButtonsDisplayOrientation;
  };
  /** If true, the Preview is rendered inside the Flow Viewer. */
  isFlowBuilder?: boolean;
  /** if true, it's a skill dialogue. */
  isModDialogue: boolean;
  plugin?: {
    dialogues: [
      {
        id: string | number;
        slug: string;
        title: Record<string, string>;
        replies: Array<{ id: string | number; languageCode: string; text: string }>;
        buttonsDisplayOrientation: ButtonsDisplayOrientation;
      },
    ];
  };
  /** List of tabs that are shown. */
  tabsShownMap: TabsShown;
  /** Function to imperatively add extra tabs to show. */
  setTabsToShowExtra: React.Dispatch<React.SetStateAction<TabName[]>>;
  shouldShowSmartReplies?: boolean;
}

const Preview = ({
  dialogue,
  isFlowBuilder,
  plugin,
  isModDialogue,
  className,
  tabsShownMap,
  setTabsToShowExtra,
  shouldShowSmartReplies = false,
}: Props) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { values: stateValues } = useFormState();

  const [{ selectedLanguage, currentLanguage }] = useCurrentLanguage();
  const currentRuleId = useCurrentRuleId();
  const { buildIdObject } = useBotOrSkill();
  const dialogueType = useDialogueType();
  const values = isFlowBuilder ? dialogue! : stateValues; // Banging because it's always an object in Flow Builder (after loading)
  const dialogueTypeParam = searchParams.get('dialogueType');

  const isDialogueWithUserInput = ['fallback', 'dialogue'].includes(dialogueType as string);
  const isIntentDialogue = stateValues.dialogueType === DIALOGUE_TYPES.INTENT;

  const replyName = isModDialogue ? 'modReplies' : 'replies';
  const buttonName = isModDialogue ? 'modButtons' : 'buttons';
  const imageName = isModDialogue ? 'modImageCarousels' : 'imageCarousels';
  const videoName = isModDialogue ? 'modVideoSources' : 'videoSources';
  const replies = useBuildItems(values, selectedLanguage, currentRuleId, replyName);
  const smartReplies = useBuildItems(values, selectedLanguage, currentRuleId, 'smartReplies');
  const buttons = useBuildItems(values, selectedLanguage, currentRuleId, buttonName);
  const imageCarousel = useBuildItems(values, selectedLanguage, currentRuleId, imageName)?.[0];
  const replyText = getReplyText(replies[0], isIntentDialogue);
  const smartReplyContent = smartReplies[0]?.content;
  const maxSmartReplyLength = 400;
  const truncatedSmartReply =
    smartReplyContent && smartReplyContent.length > maxSmartReplyLength
      ? `${smartReplyContent.substring(0, smartReplyContent.lastIndexOf(' ', maxSmartReplyLength))}...`
      : smartReplyContent;

  // @ts-expect-error convert getBuildUrl to TS
  const dialogueUrl = getBuildUrl({ buildIdObject, dialogueType: dialogue?.dialogueType, target: dialogue?.id });

  const messagePathname = dialogue && isFlowBuilder ? dialogueUrl : pathname;
  const messageURL = (view: 'input' | 'output', tab?: TabName) => {
    searchParams.delete('view');

    if (view) {
      searchParams.set('formContent', view);
    } else {
      searchParams.delete('formContent');
    }

    if (tab) {
      searchParams.set('tab', tab);
    } else {
      searchParams.delete('tab');
    }

    return {
      pathname: messagePathname,
      search: searchParams.toString(),
    };
  };

  const form = findFormByLanguageAndRule(values?.buildForms, selectedLanguage, currentRuleId);

  const hasPreview =
    replies.length > 0 ||
    smartReplies.length > 0 ||
    buttons.length > 0 ||
    imageCarousel ||
    hasVideo(values, selectedLanguage, videoName, currentRuleId || DEFAULT_RULE_ID) ||
    form;

  return (
    <div className={cx(styles.messageLines, className)}>
      {isDialogueWithUserInput && (
        <MessageClickable to={messageURL('input')} isFromUser>
          <ChatBubble
            className={cx(styles.messageFromUser, {
              [styles.messageIntent]: dialogueTypeParam === DIALOGUE_TYPES.INTENT || values.intent,
            })}
            isFromUser
          >
            {dialogueTypeParam === DIALOGUE_TYPES.INTENT || values.intent
              ? values.intent || 'User intent'
              : 'Input from user'}
          </ChatBubble>
        </MessageClickable>
      )}

      {!hasPreview && !isFlowBuilder ? (
        <EmptyPreview
          dialogueUrl={dialogueUrl}
          isFlowBuilder={isFlowBuilder}
          setTabsToShowExtra={setTabsToShowExtra}
          tabsShownMap={tabsShownMap}
        />
      ) : (
        <>
          {smartReplies.length > 0 && shouldShowSmartReplies && (
            <MessageClickable to={messageURL('output', 'smart reply')} className={styles.smartReply}>
              <SplitChatBubble
                text={truncatedSmartReply} // Use truncated text for display
                hasImage={Boolean(imageCarousel)}
              />
            </MessageClickable>
          )}

          {replies.length > 0 && (
            <MessageClickable to={messageURL('output', 'reply')}>
              <SplitChatBubble text={replyText} hasImage={Boolean(imageCarousel)} />
            </MessageClickable>
          )}
          {imageCarousel && (
            <MessageClickable to={messageURL('output', 'image')} isCarousel={imageCarousel}>
              <ImageCarouselPreview imageCarousel={imageCarousel} />
            </MessageClickable>
          )}

          {hasVideo(values, selectedLanguage, videoName, currentRuleId || DEFAULT_RULE_ID) && (
            <MessageClickable to={messageURL('output', 'video')}>
              <VideoEmbedPreview
                source={get(values, `${videoName}[${selectedLanguage}].${currentRuleId || DEFAULT_RULE_ID}`)!}
              />
            </MessageClickable>
          )}

          {form && (
            <MessageClickable to={messageURL('output', 'form')}>
              <FormPreview form={form} />
            </MessageClickable>
          )}

          {buttons.length > 0 && (
            <MessageClickable
              className={cx(styles.previewButtonsWrapper, {
                [styles.previewButtonsHorizontal]: values.buttonsDisplayOrientation === 'HORIZONTAL',
              })}
              to={messageURL('output', 'button')}
            >
              <ChatbubbleButtons buttons={buttons} />
            </MessageClickable>
          )}

          {!!plugin && (
            <div className={styles.pluginReplies}>
              <MessageClickable to={messageURL('output', 'advanced options')}>
                {plugin.dialogues.map((item) => (
                  <PluginDialogue key={`plugin-preview-${item.id}`} dialogue={item} currentLanguage={currentLanguage} />
                ))}
              </MessageClickable>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Preview;
