import arrayMutators from 'final-form-arrays';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import {
  Condition,
  FormErrors,
  GuardUnsaved,
  LoaderSwitch,
  PageBar,
  Panel,
  Select,
  SelectLanguage,
  ToggleSwitch,
} from 'frontend/components';
import { feedbackFormTypes } from 'frontend/constants';

import CustomizeFeedbackButton from './CustomizeFeedbackButton';
import styles from './styles.scss';
import { useChatbubbleForm } from '../../../hooks';
import chatBubbleStyles from '../../styles.scss';

const SUBSCRIBE = {};

const CustomerSatisfaction = () => {
  const { onSubmit, initialValues, loading, languagesLoading, languages, happyOrNotEnabled } = useChatbubbleForm();

  return (
    <LoaderSwitch loading={loading || languagesLoading} size="large">
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        subscribe={SUBSCRIBE}
        render={({ handleSubmit }) => (
          <>
            <GuardUnsaved />
            <form onSubmit={handleSubmit}>
              <PageBar>
                <PageBar.FormButtons />
              </PageBar>
              <FormErrors className={chatBubbleStyles.formErrors} />
              <div className={chatBubbleStyles.chatbubbleSettingsWrapper}>
                <Panel>
                  <div className={chatBubbleStyles.selectLanguage}>
                    {languages.length > 1 && <SelectLanguage languages={languages} supportVariants={false} />}
                  </div>
                  <h3 className="m-b-lg">Customer satisfaction</h3>
                  <p className={styles.feedbackExplanation}>
                    Ask users to rate their experience when closing the chat bubble using a feedback form. Once a user
                    has seen the form, it will not be shown again until new messages have been sent.
                  </p>
                  <div className={styles.select}>
                    <label className="m-t-md" htmlFor="Select-feedback-form-type">
                      Feedback form type
                    </label>
                    <Field component={Select} id="Select-feedback-form-type" name="feedbackFormType">
                      <Select.Option value={feedbackFormTypes.NONE} label="None" />
                      <Select.Option value={feedbackFormTypes.BINARY} label="Yes / No" />
                      <Select.Option value={feedbackFormTypes.EMOJIS} label="Emojis" />
                      <Select.Option value={feedbackFormTypes.EXTERNAL_LINK} label="External link" />
                      {happyOrNotEnabled && (
                        <Select.Option value={feedbackFormTypes.HAPPY_OR_NOT} label="Four emojis, green and red" />
                      )}
                    </Field>
                  </div>
                  <Condition when="feedbackFormType" isNot={feedbackFormTypes.NONE}>
                    <CustomizeFeedbackButton />
                    <br />
                    <strong>Note:</strong> The feedback form will not appear when testing in the platform. But hey, go
                    to the{' '}
                    <Link className="link" to="../demo-page">
                      demo page
                    </Link>{' '}
                    and test it there instead!
                    <div className="m-t-4 m-b-4">
                      <ToggleSwitch
                        name="noFeedbackAfterHandover"
                        className="m-r-2"
                        status={["Don't show feedback form after handover"]}
                      />
                    </div>
                  </Condition>
                </Panel>
              </div>
            </form>
          </>
        )}
      />
    </LoaderSwitch>
  );
};

export default CustomerSatisfaction;
