import { Link, useParams } from 'react-router-dom';

import { Loader, PageContent, Panel } from 'frontend/components';

import { useOrganizationUserDetail } from './hooks';
import styles from './styles.scss';
import { RoleList } from '../../components';

const UserDetail = () => {
  const { organizationId, userId } = useParams();
  const { loading, user, organizationRoles, botMemberships } = useOrganizationUserDetail({
    userId,
    organizationId,
  });

  if (loading) return <Loader />;

  return (
    <PageContent>
      <Panel centered>
        <h1>{user?.profile?.fullName}</h1>

        <div className={styles.roles}>
          <h3>Organization roles:</h3>
          <p>
            <Link to={`/organization/${organizationId}/settings/roles`}>
              <RoleList roles={organizationRoles} />
            </Link>
          </p>
        </div>

        {botMemberships?.map(({ bot, roles }) => (
          <div className={styles.roles} key={bot.id}>
            <h3>
              Bot roles for <Link to={`/workspace/${bot.id}`}>{bot.name}</Link>:
            </h3>
            <p>
              <RoleList roles={roles} />
            </p>
          </div>
        ))}
      </Panel>
    </PageContent>
  );
};

export default UserDetail;
