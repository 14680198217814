import { Navigate, Route, Routes } from 'react-router-dom';

import {
  Conversion,
  Email,
  Feedback,
  Handover as HandoverAnalytics,
  Nudge,
  Sessions,
} from 'frontend/features/Analytics';
import NewAnalytics from 'frontend/features/Analytics/views/NewAnalytics/NewAnalytics';

export default function AnalyticsRoutes() {
  return (
    <Routes>
      <Route path="new-analytics/*" element={<NewAnalytics />} />
      <Route path="sessions/*" element={<Sessions />} />
      <Route path="feedback" element={<Feedback />} />
      <Route path="handover" element={<HandoverAnalytics />} />
      <Route path="nudge" element={<Nudge />} />
      <Route path="email" element={<Email />} />
      <Route path="conversion/*" element={<Conversion />} />

      <Route path="/" element={<Navigate to="new-analytics" replace />} />
    </Routes>
  );
}
