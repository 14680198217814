import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

export default PropTypes.shape({
  id: IDType.isRequired,
  title: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  __typename: PropTypes.oneOf(['OrganizationRoleType', 'BotRoleType']),
});
