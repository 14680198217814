import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { EmptyState } from 'frontend/components/Stats';
import { StatsPanel } from 'frontend/features/Analytics/components';
import { useFeedbackTypes, useSageData } from 'frontend/features/Analytics/hooks';
import { FeedbackType } from 'frontend/features/Analytics/propTypes';
import { SAGE_RESOURCE, sageScope } from 'frontend/state/dux/analytics/sageScope';
import { formatNumber } from 'frontend/utils';

import styles from './FeedbackSummary.scss';
import { adjustNoFeedbackRatio, asPercentage, mapData } from './utils';

const FeedbackBox = ({ value, children, color }) => (
  <div className={styles.box} style={{ borderColor: color }}>
    <div className={styles.display}>{children}</div>
    <div className={styles.value} style={{ background: color }}>
      {value}
    </div>
  </div>
);

FeedbackBox.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

const NoFeedback = ({ noFeedback }) =>
  noFeedback ? (
    <FeedbackBox value={asPercentage(noFeedback.ratio)} color={noFeedback.color}>
      {noFeedback.label}
    </FeedbackBox>
  ) : null;

NoFeedback.propTypes = {
  noFeedback: FeedbackType,
};

const NumberSummary = ({ noFeedback, total, noFeedbackTotal }) => (
  <>
    <p className={styles.statsSummary}>
      <strong>Feedback received:</strong> {formatNumber(total)}
    </p>
    {noFeedback && (
      <p className={styles.statsSummary}>
        <strong>No feedback:</strong> {formatNumber(noFeedbackTotal)}
      </p>
    )}
  </>
);

NumberSummary.propTypes = {
  noFeedback: FeedbackType,
  total: PropTypes.number,
  noFeedbackTotal: PropTypes.number,
};

const EmojiSummary = ({ emojiData: { total, data, noFeedback } }) => (
  <>
    <NumberSummary noFeedback={noFeedback} noFeedbackTotal={noFeedback?.total || 0} total={total} />
    <div className={styles.feedback}>
      {data
        .filter(({ ratio }) => ratio > 0.0)
        .map(adjustNoFeedbackRatio(noFeedback))
        .reverse()
        .map(({ imageUrl, color, ratio }) => (
          <FeedbackBox key={color} value={asPercentage(ratio)} color={color}>
            <img className={styles.emojiIcon} src={imageUrl} alt="" />
          </FeedbackBox>
        ))}
      <NoFeedback noFeedback={noFeedback} />
    </div>
  </>
);

EmojiSummary.propTypes = {
  emojiData: FeedbackType.isRequired,
  noFeedback: FeedbackType,
};

const BinarySummary = ({ binaryData: { total, data, noFeedback } }) => (
  <>
    <NumberSummary noFeedback={noFeedback} noFeedbackTotal={noFeedback?.total || 0} total={total} />
    <div className={styles.feedback}>
      {data
        .filter(({ ratio }) => ratio > 0.0)
        .map(adjustNoFeedbackRatio(noFeedback))
        .reverse()
        .map(({ label, color, ratio }) => (
          <FeedbackBox key={color} value={asPercentage(ratio)} color={color}>
            {label}
          </FeedbackBox>
        ))}
      <NoFeedback noFeedback={noFeedback} />
    </div>
  </>
);

BinarySummary.propTypes = {
  binaryData: FeedbackType.isRequired,
  noFeedback: FeedbackType,
};

const HappyOrNotSummary = ({ happyOrNotData: { total, data, noFeedback } }) => (
  <>
    <NumberSummary noFeedback={noFeedback} noFeedbackTotal={noFeedback?.total || 0} total={total} />
    <div className={styles.feedback}>
      {data
        .filter(({ ratio }) => ratio > 0.0)
        .reverse()
        .map(adjustNoFeedbackRatio(noFeedback))
        .map(({ imageUrl, color, ratio }) => (
          <FeedbackBox key={color} value={asPercentage(ratio)} color={color}>
            <img className={styles.emojiIcon} src={imageUrl} alt="" />
          </FeedbackBox>
        ))}
      <NoFeedback noFeedback={noFeedback} />
    </div>
  </>
);

HappyOrNotSummary.propTypes = {
  happyOrNotData: FeedbackType.isRequired,
  noFeedback: FeedbackType,
};

const Summary = ({ emojiData, binaryData, happyOrNotData }) => (
  <>
    {emojiData.total > 0 && <EmojiSummary emojiData={emojiData} />}
    {emojiData.total > 0 && (binaryData.total > 0 || happyOrNotData.total > 0) && <br />}
    {binaryData.total > 0 && <BinarySummary binaryData={binaryData} />}
    {binaryData.total > 0 && happyOrNotData.total > 0 && <br />}
    {happyOrNotData.total > 0 && <HappyOrNotSummary happyOrNotData={happyOrNotData} />}
    {emojiData.total === 0 && binaryData.total === 0 && happyOrNotData.total === 0 && <EmptyState />}
  </>
);

Summary.propTypes = {
  emojiData: FeedbackType.isRequired,
  binaryData: FeedbackType.isRequired,
  happyOrNotData: FeedbackType.isRequired,
};

const FeedbackSummary = ({ filters, className }) => {
  const { botId } = useParams();
  const scope = sageScope(SAGE_RESOURCE.BOT, botId);
  const { loading, error, data } = useSageData(scope, '/feedback/summary', filters);
  const {
    loading: sessionLoading,
    error: sessionError,
    data: totalSessions,
  } = useSageData(scope, '/sessions/total', filters, { apiVersion: 'v2' });

  // Feedback filtering is done in the frontend and not by sage
  const [feedbackFilter] = useFeedbackTypes();

  const mappedData = useMemo(() => {
    if (!data || loading || sessionLoading) {
      return null;
    }
    return mapData(data, feedbackFilter, totalSessions);
  }, [loading, sessionLoading, feedbackFilter, totalSessions, data]);

  return (
    <StatsPanel loading={loading || sessionLoading} error={error || sessionError} className={className} noPadding>
      <h3 className={styles.title}>Feedback summary</h3>
      <div className={styles.content}>
        <p className={styles.description}>User satisfaction after chatting with the bot.</p>
        {mappedData && <Summary {...mappedData} />}
      </div>
    </StatsPanel>
  );
};

FeedbackSummary.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.shape({}),
};

export default FeedbackSummary;
