import { List } from 'frontend/components';
import type { Role } from 'frontend/features/Organization/types';

import { sortRoles } from '../../utils';

interface RoleListProps {
  roles: Role[];
}
const RoleList = ({ roles }: RoleListProps) => {
  const sortedRoles = sortRoles(roles, 'title');

  return (
    <List renderItem={(item: Role) => <List.Item key={item.title}>{item.title}</List.Item>} dataSource={sortedRoles} />
  );
};

export default RoleList;
