import React from 'react';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

import {
  Aerial,
  CodeInput,
  KindlyBlob,
  Language,
  MoveToArchive,
  Settings,
  UserCheck,
  Users,
} from 'frontend/assets/icons';
import { PageContent } from 'frontend/components';
import { NotFound } from 'frontend/features';
import {
  BotBackups,
  CustomRoles,
  ImportExport,
  Memberships,
  MessageBackups,
  Privacy,
  Security,
} from 'frontend/features/BotSettings';
import {
  Alerts,
  Appearance,
  Authentication,
  Behavior,
  CompletionPage,
  Demo,
  CustomerSatisfaction as LegacyCustomerSatisfaction,
  TextContent,
  WelcomePage,
} from 'frontend/features/BotSettings/Chatbubble/components';
import CustomerFeedback from 'frontend/features/BotSettings/Chatbubble/components/CustomerFeedback/CustomerFeedback';
import { BotIndexStatus, BotWebhooks } from 'frontend/features/BotSettings/Developer/components';
import Engagement from 'frontend/features/BotSettings/Engagement';
import { Advanced, Beta, BotMeta, Build } from 'frontend/features/BotSettings/General/components';
import { Handover } from 'frontend/features/BotSettings/InboxSettings/views/Handover';
import {
  BotLanguages,
  Entities,
  NLPandML,
  SpellingCorrection,
} from 'frontend/features/BotSettings/Languages/components';
import LanguageDetection from 'frontend/features/BotSettings/Languages/components/LanguageDetection';
import DefaultRoles from 'frontend/features/BotSettings/Roles/components/DefaultRoles';
import SubNav from 'frontend/features/SubNav/SubNav';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

import styles from './sharedStyles.scss';

const FixedLayout = () => {
  const { botId } = useParams();
  const { hasBotPerms } = useMyPermissions({ botId });
  const canManageKindlyEntities = hasBotPerms('manage_kindly_entities');
  const canManageFeatureFlags = hasBotPerms('manage_feature_flags');

  return (
    <PageContent hasFormValidation className={styles.container}>
      <SubNav>
        <SubNav.Accordion icon={Settings} title="General" to="../general/details">
          <SubNav.AccordionItem to="../general/details">Details</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../general/build">Build</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../general/privacy">Privacy</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../general/security">Security</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../general/advanced">Advanced</SubNav.AccordionItem>
          {canManageFeatureFlags && (
            <SubNav.AccordionItem to="../general/beta" className="kindlyAdminManager">
              Beta
            </SubNav.AccordionItem>
          )}
        </SubNav.Accordion>

        <SubNav.Accordion icon={KindlyBlob} title="Kindly Chat" to="../chat/behavior">
          <SubNav.AccordionItem to="../chat/behavior">Behavior</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../chat/customer-satisfaction">Customer satisfaction</SubNav.AccordionItem>
          {canManageFeatureFlags && (
            <SubNav.AccordionItem to="../chat/customer-feedback" className="kindlyAdminManager">
              Customer feedback (beta)
            </SubNav.AccordionItem>
          )}
          <SubNav.AccordionItem to="../chat/appearance">Appearance</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../chat/text-content">Text content</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../chat/welcome">Welcome page</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../chat/alerts">Alerts</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../chat/authentication">Authentication</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../chat/completion-page">Completion page</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../chat/demo-page">Demo page</SubNav.AccordionItem>
        </SubNav.Accordion>

        <SubNav.Accordion icon={Language} title="Languages" to="../languages/bot-languages">
          <SubNav.AccordionItem to="../languages/bot-languages">Bot Languages</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../languages/nlp-and-ml">NLP & ML</SubNav.AccordionItem>
          {canManageFeatureFlags && (
            <SubNav.AccordionItem to="../languages/spelling-correction" className="kindlyAdminManager">
              Spelling correction
            </SubNav.AccordionItem>
          )}
          <SubNav.AccordionItem to="../languages/language-detection">Language detection</SubNav.AccordionItem>
          {canManageKindlyEntities && <SubNav.AccordionItem to="../languages/entities">Entities</SubNav.AccordionItem>}
        </SubNav.Accordion>

        <SubNav.Accordion icon={MoveToArchive} title="Inbox" to="../inbox/handover">
          <SubNav.AccordionItem to="../inbox/handover">Handover</SubNav.AccordionItem>
        </SubNav.Accordion>

        <SubNav.Item icon={Users} to="../memberships">
          Memberships
        </SubNav.Item>

        <SubNav.Accordion icon={UserCheck} title="Roles" to="../roles/default-roles">
          <SubNav.AccordionItem to="../roles/default-roles">Default roles</SubNav.AccordionItem>
          <SubNav.AccordionItem to="../roles/custom-roles">Custom roles</SubNav.AccordionItem>
        </SubNav.Accordion>

        <SubNav.Accordion icon={CodeInput} title="Developer" to="../developer/webhooks">
          <SubNav.AccordionItem to="../developer/webhooks">Webhooks</SubNav.AccordionItem>
          {canManageFeatureFlags && (
            <SubNav.AccordionItem to="../developer/index" className="kindlyAdminManager">
              Index status
            </SubNav.AccordionItem>
          )}
        </SubNav.Accordion>

        {canManageFeatureFlags && (
          <SubNav.Item icon={Aerial} to="../engagement" className="kindlyAdminManager">
            Engagement
          </SubNav.Item>
        )}
      </SubNav>

      <Outlet />
    </PageContent>
  );
};

export default function SettingsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="general" replace />} />

      <Route element={<FixedLayout />}>
        <Route path="general">
          <Route index element={<Navigate to="details" replace />} />
          <Route path="details" element={<BotMeta />} />
          <Route path="build" element={<Build />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="security" element={<Security />} />
          <Route path="advanced" element={<Advanced />} />
          <Route path="beta" element={<Beta />} />
        </Route>

        <Route path="chat">
          <Route index element={<Navigate replace to="behavior" />} />
          <Route path="behaviour" element={<Navigate replace to="behavior" />} />
          <Route path="behavior" element={<Behavior />} />
          <Route path="customer-satisfaction" element={<LegacyCustomerSatisfaction />} />
          <Route path="customer-feedback" element={<CustomerFeedback />} />
          <Route path="appearance" element={<Appearance />} />
          <Route path="text-content" element={<TextContent />} />
          <Route path="welcome" element={<WelcomePage />} />
          <Route path="alerts" element={<Alerts />} />
          <Route path="authentication" element={<Authentication />} />
          <Route path="completion-page" element={<CompletionPage />} />
          <Route path="demo-page" element={<Demo />} />
        </Route>

        <Route path="languages">
          <Route index element={<Navigate to="bot-languages" replace />} />
          <Route path="bot-languages" element={<BotLanguages key="bot-languages" />} />
          <Route path="nlp-and-ml" element={<NLPandML />} />
          <Route path="language-detection" element={<LanguageDetection />} />
          <Route path="spelling-correction" element={<SpellingCorrection />} />
          <Route path="entities" element={<Entities />} />
        </Route>

        <Route path="inbox">
          <Route index element={<Navigate to="handover" />} />
          <Route path="handover" element={<Handover />} />
        </Route>

        <Route path="memberships">
          <Route index element={<Memberships />} />
        </Route>

        <Route path="roles">
          <Route index element={<Navigate to="default-roles" replace />} />
          <Route path="default-roles" element={<DefaultRoles />} />
          <Route path="custom-roles" element={<CustomRoles />} />
        </Route>

        <Route path="developer">
          <Route index element={<Navigate to="webhooks" />} />
          <Route path="webhooks" element={<BotWebhooks />} />
          <Route path="index" element={<BotIndexStatus />} />
        </Route>

        <Route path="engagement">
          <Route index element={<Engagement />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="bot-backups" element={<BotBackups />} />
      <Route path="message-backups" element={<MessageBackups />} />
      <Route path="import-export/*" element={<ImportExport />} />
      <Route path="takeover" element={<Navigate to="../../analytics/handover" replace />} />
    </Routes>
  );
}
