import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BotDocument, GetSkillDocument, OrganizationMetaDocument } from 'frontend/api/generated';

import useBotOrSkill from './useBotOrSkill';

export default (pageTitle = '') => {
  const { isBot, botId, isSkill, skillId } = useBotOrSkill({ ignoreNoBotOrSkill: true });
  const { organizationId } = useParams();

  const { data: botData, loading: botLoading } = useQuery(BotDocument, {
    variables: { botId: botId as string },
    skip: !isBot,
  });
  const { data: skillData, loading: skillLoading } = useQuery(GetSkillDocument, {
    variables: { skillId: skillId! },
    skip: !isSkill || !skillId,
  });
  const { data: organizationData, loading: organizationLoading } = useQuery(OrganizationMetaDocument, {
    variables: { id: organizationId! },
    skip: !organizationId,
  });

  const name = get(get(botData, 'bot') || get(skillData, 'skill') || get(organizationData, 'organization'), 'name');

  const pageTitleSegment = pageTitle ? `${pageTitle} - ` : '';
  const nameSegment = name ? `${name} - ` : '';
  const title = `${pageTitleSegment}${nameSegment}Kindly`;

  useEffect(() => {
    document.title = title;

    return () => {
      // Use just 'Kindly' when no tab title is specified
      document.title = 'Kindly';
    };
  }, [title]);

  const loading = botLoading || skillLoading || organizationLoading;
  return { loading, botData, skillData, organizationData };
};
