import cx from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { normalizeKey } from 'frontend/utils';

import styles from './styles.scss';
import { ROLE_DESCRIPTIONS } from '../../../../constants';
import { RoleType } from '../../../../propTypes';
import { roleIdToFieldName } from '../../utils';

const Role = ({ role, values, change }) => {
  const { id, title } = role;
  const description = role.description || ROLE_DESCRIPTIONS[normalizeKey(title)];
  const fieldName = roleIdToFieldName(id);
  const isSelected = values[fieldName];
  const onClick = useCallback(() => change(fieldName, !isSelected), [change, fieldName, isSelected]);

  return (
    <button
      className={cx(styles.selectionBox, {
        [styles.selectionBoxActive]: isSelected,
      })}
      onClick={onClick}
      type="button"
    >
      <h3 className={styles.roleTitle}>{title}</h3>
      <p className={styles.roleDescription}>{description || 'No description'}</p>
    </button>
  );
};

Role.propTypes = {
  role: RoleType.isRequired,
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool])).isRequired,
  change: PropTypes.func.isRequired,
};

export default Role;
