import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';

import { OrganizationMemberDocument, type OrganizationRoleType, type UserType } from 'frontend/api/generated';
import { ChevronDown } from 'frontend/assets/icons';
import { Icon } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import type { Role } from 'frontend/features/Organization/types';

import styles from './styles.scss';
import RoleDetails from '../../../../components/RoleDetails';
import DefinedRoles from '../DefinedRoles';

const DISPLAY_TYPES = ['PREDEFINED', 'DETAILED'] as const;

const getMemberUserId = ({
  userId,
  values,
  users,
}: {
  userId?: string;
  values?: { email: string };
  users?: UserType[];
}): string | undefined => {
  if (userId) return userId;
  const user = (users || []).find((us) => values?.email === us?.username);
  return user?.id;
};

const useRelevantRoles = ({ roles, users, disallowOrganizationRoles, organizationId, userId }) => {
  const { values } = useFormState<{ email: string }>();
  const memberUserId = getMemberUserId({ userId, values, users });
  const { data } = useQuery(OrganizationMemberDocument, {
    variables: { userId: memberUserId!, organizationId },
    skip: !disallowOrganizationRoles || !memberUserId,
  });

  return useMemo(() => {
    if (!disallowOrganizationRoles) return roles;

    const allUserPermissions = ((data?.organization?.member?.membership?.roles ?? []) as OrganizationRoleType[]).reduce(
      (allPermissions: string[], { permissions }) => [...allPermissions, ...(permissions ?? ([] as string[]))],
      [],
    );

    return roles.filter(
      ({ permissions }) => !permissions.every((permission) => allUserPermissions.includes(permission)),
    );
  }, [data, disallowOrganizationRoles, roles]);
};

type Props = {
  users?: UserType[];
  roles: Role[];
  allPermissions: string[];
  disallowOrganizationRoles: boolean;
  organizationId?: string;
  userId?: string;
};
const UserRole = ({ users, roles, allPermissions, disallowOrganizationRoles, organizationId, userId }: Props) => {
  const [display, setDisplay] = useState<(typeof DISPLAY_TYPES)[number]>('PREDEFINED');
  const showDefinedRoles = DISPLAY_TYPES.includes(display);
  const showDetailsView = display === 'DETAILED';

  const toggleDetailed = useCallback(
    () => setDisplay((currentDisplay) => (currentDisplay === 'DETAILED' ? 'PREDEFINED' : 'DETAILED')),
    [],
  );

  const relevantRoles = useRelevantRoles({ roles, users, disallowOrganizationRoles, organizationId, userId });

  return (
    <Modal.Content>
      <p className="m-t-0">A role will define what kind of access and changes a member can do.</p>
      {showDefinedRoles && (
        <>
          <DefinedRoles roles={relevantRoles} />

          <button type="button" className={styles.showDetails} onClick={toggleDetailed}>
            {display === 'DETAILED' ? 'Hide' : 'Show'} role details
            <Icon
              component={ChevronDown}
              className={cx(styles.triangle, {
                [styles.rotateTriangle]: display === 'DETAILED',
              })}
              color="black"
            />
          </button>
        </>
      )}
      {showDetailsView && <RoleDetails roles={roles} allPermissions={allPermissions} />}
    </Modal.Content>
  );
};

export default UserRole;
