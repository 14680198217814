import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

import { SORT_ITEMS } from './constants';

export const SortType = PropTypes.shape({
  sortBy: PropTypes.oneOf(Object.values(SORT_ITEMS)),
  setSortBy: PropTypes.func.isRequired,
  reverse: PropTypes.bool.isRequired,
  toggleReverse: PropTypes.func.isRequired,
});

export const InvitationType = PropTypes.shape({
  id: IDType.isRequired,
  email: PropTypes.string.isRequired,
  invitedAt: PropTypes.string.isRequired,
});
