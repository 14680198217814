import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Field } from 'react-final-form';

import { Input } from 'frontend/components';
import { chain, max as maxLength, required, url as validateUrl } from 'frontend/form/validators';

const InputField = ({ name, max, label, multiline, className, url = false }) => {
  const validate = useMemo(() => {
    if (max) return maxLength(max);
    if (url) return chain(required, validateUrl);
    return undefined;
  }, [max, url]);

  return (
    <Field
      name={name}
      component={Input}
      multiline={multiline}
      inputLimit={max}
      validate={validate}
      aria-label={label}
      className={className}
      label={label}
    />
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  max: PropTypes.number,
  url: PropTypes.bool,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  className: PropTypes.string,
};

export default InputField;
