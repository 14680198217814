import styles from '../FeedbackRating/FeedbackRating.scss';

export const emojiColors = {
  1: styles.errorColor,
  2: styles.neutralColor,
  3: styles.successColor,
};
export const binaryColors = {
  1: styles.errorColor,
  2: styles.successColor,
};
export const happyOrNotColors = {
  1: styles.happyOrNotVeryUnhappyColor,
  2: styles.happyOrNotUnhappyColor,
  3: styles.happyOrNotHappyColor,
  4: styles.happyOrNotVeryHappyColor,
};
