import cx from 'classnames';

import styles from './Tooltip.scss';

interface TooltipProps {
  children: React.ReactNode;
  className?: string;
}

const Tooltip = ({ children, className }: TooltipProps) => (
  <div className={cx(styles.tooltipWrapper, className)}>{children}</div>
);

interface TooltipContentProps {
  children: React.ReactNode;
  className?: string;
  maxWidth?: string;
}

const Body = ({ children, className, maxWidth }: TooltipContentProps) => (
  <div className={cx(styles.tooltip, className)} style={{ '--_maxWidth': maxWidth } as React.CSSProperties}>
    {children}
  </div>
);

const Standalone = ({ children, className, maxWidth }: TooltipContentProps) => (
  <span
    contentEditable={false}
    className={styles.standalone}
    style={{ '--_maxWidth': maxWidth } as React.CSSProperties}
  >
    <span className={className}>{children}</span>
  </span>
);

Tooltip.Body = Body;
Tooltip.Standalone = Standalone;

export default Tooltip;
