import { useMutation } from '@apollo/client';

import {
  GetNotificationsDocument,
  MarkAllNotificationsAsReadDocument,
  MarkNotificationAsReadDocument,
  type UserType,
} from 'frontend/api/generated';
import { useMixpanel } from 'frontend/hooks';

export default () => {
  const { mixpanel } = useMixpanel();
  const [markAllAsRead] = useMutation(MarkAllNotificationsAsReadDocument, {
    update: (client) => {
      mixpanel.track('Mark all notifications read');
      const data = client.readQuery<{ me: UserType }>({ query: GetNotificationsDocument });
      if (!data || !data?.me?.notifications) {
        return;
      }

      const updatedNotifications = data.me.notifications.map((item) => ({ ...item, read: true }));

      client.writeQuery({
        query: GetNotificationsDocument,
        data: {
          ...data,
          me: {
            ...data.me,
            notifications: updatedNotifications,
            unreadNotifications: 0,
          },
        },
      });
    },
  });

  const [markAsRead] = useMutation(MarkNotificationAsReadDocument, {
    update: (client) => {
      const data = client.readQuery<{ me: UserType }>({ query: GetNotificationsDocument });
      if (!data || !data?.me?.unreadNotifications) {
        return;
      }

      client.writeQuery({
        query: GetNotificationsDocument,
        data: {
          ...data,
          me: {
            ...data.me,
            unreadNotifications: data.me.unreadNotifications - 1,
          },
        },
      });
    },
  });

  return {
    markAllAsRead,
    markAsRead,
  };
};
