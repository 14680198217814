import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { LoaderSwitch, Search } from 'frontend/components';

import styles from './OrganizationList.scss';
import { CreateOrganizationButton } from './components';
import { OrganizationType } from '../../propTypes';
import OrgCard from '../OrgCard';

const searchFields = Object.freeze({
  ID: 'id',
  NAME: 'name',
});

const searchKey = Object.values(searchFields);

const OrganizationList = ({ organizations, loading = false, canAddOrganization }) => {
  const organizationsData = useMemo(
    () =>
      (organizations || []).map((org) => ({
        ...org,
        key: org.id,
        options: { id: org.id },
        subscriptions: { orgId: org.id, orgName: org.name, subscriptions: org.subscriptions },
      })),
    [organizations],
  );
  const [searchResults, setSearchResults] = useState(organizationsData);
  const sortedOrganizationList = useMemo(() => orderBy(searchResults, 'createdAt', 'desc'), [searchResults]);

  return (
    <LoaderSwitch loading={loading} size="large">
      <div className={styles.container}>
        <div className={styles.header}>
          <Search
            setSearchResults={setSearchResults}
            searchItems={organizationsData}
            searchKey={searchKey}
            placeholder="Search organizations"
            aria-label="Search organizations"
            className={styles.orgListSearch}
            icon
          />
          {canAddOrganization && <CreateOrganizationButton />}
        </div>
        <div className={styles.listHeader}>
          <h4>Organizations</h4>
          <h4>Statistics</h4>
        </div>
        {!sortedOrganizationList.length && <div>No organizations</div>}
        <div className={styles.listContainer}>
          {sortedOrganizationList
            .filter(({ id }) => id)
            .map((org) => (
              <Link key={org.id} to={`/organization/${org.id}`}>
                <OrgCard name={org.name} logo={org.logo} botCount={org.botCount} skillCount={org.skillCount} />
              </Link>
            ))}
        </div>
      </div>
    </LoaderSwitch>
  );
};

OrganizationList.propTypes = {
  organizations: PropTypes.arrayOf(OrganizationType).isRequired,
  canAddOrganization: PropTypes.bool,
  loading: PropTypes.bool,
};

export default OrganizationList;
