import cx from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { DropdownButton, Pagination, Panel } from 'frontend/components';
import { DeleteInvitation } from 'frontend/features/Admin/views/Users/modals';
import { useModal } from 'frontend/features/Modals';
import { usePagination } from 'frontend/hooks';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

import styles from './PendingUsersList.scss';
import { UpdateInvitation as UpdateInvitationModal } from '../../../../modals';
import { SORT_ITEMS } from '../../constants';
import { useSortColumn } from '../../hooks';
import { InvitationType, SortType } from '../../propTypes';
import SortIcon from '../SortIcon';

const PAGE_SIZE = 15;

const Actions = ({ invitation }) => {
  const [showDeleteModal] = useModal(DeleteInvitation);
  const [showUpdateModal] = useModal(UpdateInvitationModal);
  const { id, email, organization } = invitation;

  const actions = useMemo(
    () => [
      {
        title: 'Update invitation',
        key: 'update-invitation',
        onClick: () => showUpdateModal({ id, email, organizationId: organization.id }),
      },
      {
        title: 'Delete invitation',
        key: 'delete-invitation',
        onClick: () => showDeleteModal({ id, email, organizationId: organization.id }),
      },
    ],
    [email, id, organization.id, showDeleteModal, showUpdateModal],
  );

  return (
    <DropdownButton wrapperClassName={styles.options} actions={actions}>
      <span className={styles.optionsButton} />
    </DropdownButton>
  );
};

Actions.propTypes = {
  invitation: InvitationType.isRequired,
};

const PendingUsersList = ({ invitations, sort, sort: { reverse } }) => {
  const [isSortedByEmail, onClickEmail] = useSortColumn(SORT_ITEMS.EMAIL, sort);
  const [isSortedByOrganizationRoles, onClickOrganizationRoles] = useSortColumn(SORT_ITEMS.ORGANIZATION_ROLES, sort);
  const [isSortedByCreated, onClickCreated] = useSortColumn(SORT_ITEMS.CREATED, sort);

  const [currentInvitations, page, pages, setPage] = usePagination({
    objects: invitations.filter(({ id }) => id),
    pageSize: PAGE_SIZE,
  });

  return (
    <div role="table" className={styles.invitationList}>
      <div role="row" className={styles.invitationHeader}>
        <div role="columnheader">
          <button className={styles.sortButton} onClick={onClickEmail} type="button">
            Email
            {isSortedByEmail && <SortIcon sort="email" reverse={reverse} />}
          </button>
        </div>
        <div role="columnheader">
          <button className={styles.sortButton} onClick={onClickOrganizationRoles} type="button">
            Organization roles
            {isSortedByOrganizationRoles && <SortIcon sort="organization roles" reverse={reverse} />}
          </button>
        </div>
        <div role="columnheader">
          <button className={styles.sortButton} onClick={onClickCreated} type="button">
            Created
            {isSortedByCreated && <SortIcon sort="created" reverse={reverse} />}
          </button>
        </div>
        <div role="columnheader"> </div>
      </div>
      <div className={styles.invitationsContent}>
        {currentInvitations.map((invitation) => (
          <Panel role="row" key={`invitation-${invitation.id}`} size="small">
            <div className={styles.invitationItem}>
              <div role="cell" className={cx(styles.name, { [styles.sort]: isSortedByEmail })}>
                <span>{invitation.email}</span>
              </div>
              <div role="cell" className={cx(styles.roles, { [styles.sort]: isSortedByOrganizationRoles })}>
                {invitation.organizationRolesText}
              </div>
              <div role="cell" className={cx({ [styles.sort]: isSortedByCreated })}>
                {format(invitation.invitedAt, DATE_FORMAT_DATE)}
              </div>
              <div role="cell" className={styles.actions}>
                <Actions invitation={invitation} />
              </div>
            </div>
          </Panel>
        ))}

        <div className={styles.pagination}>
          <Pagination currentPage={page} pages={pages} setPage={setPage} />
        </div>
      </div>
    </div>
  );
};

PendingUsersList.propTypes = {
  invitations: PropTypes.arrayOf(InvitationType).isRequired,
  sort: SortType.isRequired,
};

export default PendingUsersList;
