import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { GetSkillDocument } from 'frontend/api/generated';

import Menu from './Menu';

const hideBuild = (skill) => !skill.permissions.includes('read_skill_content');
const hideManageView = (skill) => !skill.permissions.includes('manage_skill');

const SkillMenu = () => {
  const { skillId } = useParams();

  const { data, loading } = useQuery(GetSkillDocument, { variables: { skillId: skillId! }, skip: !skillId });
  const skill = data?.skill;

  const noData = loading || !skill?.id;

  const menuItems = useMemo(
    () =>
      noData
        ? []
        : [
            {
              name: 'Build',
              hide: hideBuild(skill),
              slug: 'build',
              path: '/build/*',
            },
            { name: 'Publish', hide: hideManageView(skill), path: '/publish', slug: 'publish' },
            { name: 'Subscribers', path: '/subscribers', slug: 'subscribers' },
            {
              name: 'Settings',
              hide: hideManageView(skill),
              path: '/settings/*',
              slug: 'settings',
            },
          ],
    [noData, skill],
  );

  if (noData) return null;

  return <Menu menuItems={menuItems} />;
};

export default SkillMenu;
