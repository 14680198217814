import { useMutation, useQuery } from '@apollo/client';
import { isObject, mapValues, omit } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { FeedbackFormDocument, UpdateFeedbackFormDocument } from 'frontend/api/generated';
import { Condition, GuardUnsaved, LoaderSwitch, SelectLanguage, ToggleSwitch } from 'frontend/components';
import { feedbackFormTypes } from 'frontend/constants';
import { ModalForm } from 'frontend/features/Modals';
import { useCurrentLanguage, useLanguages, useToast } from 'frontend/hooks';
import { BotOrSkillParamsType } from 'frontend/propTypes';

import InputField from './InputField';
import styles from './UpdateFeedbackForm.scss';

const handleField = (currentLanguage) => (field) => (isObject(field) ? field[currentLanguage] || field.en : field);

const UpdateFeedbackForm = ({ hide, args: { feedbackFormType, botOrSkillParams } }) => {
  const { botId } = botOrSkillParams;
  const toast = useToast();
  const [{ currentLanguage }] = useCurrentLanguage();
  const [update] = useMutation(UpdateFeedbackFormDocument);

  const { languages, loading: languagesLoading } = useLanguages(botOrSkillParams);
  const { data, loading: feebackFormLoading } = useQuery(FeedbackFormDocument, { variables: { botId } });
  const feedbackForm = useMemo(() => omit(data?.chatbubbleFeedbackForm ?? {}, ['id', '__typename']), [data]);

  const initialValues = useMemo(
    () => mapValues(feedbackForm, handleField(currentLanguage)),
    [feedbackForm, currentLanguage],
  );

  const onSubmit = useCallback(
    async (currentLanguageValues) => {
      const chatbubbleFeedbackFormInput = Object.entries(currentLanguageValues).reduce((result, [key, value]) => {
        const previousValue = feedbackForm[key];
        if (isObject(previousValue)) {
          return {
            ...result,
            [key]: { ...previousValue, [currentLanguage]: value },
          };
        }
        return { ...result, [key]: value };
      }, {});

      await update({ variables: { botId, chatbubbleFeedbackFormInput } });
      toast.success('The feedback form has been customized!');
    },
    [botId, currentLanguage, feedbackForm, toast, update],
  );

  return (
    <ModalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      hide={hide}
      onOkText="Save"
      title="Customize feedback form"
      confirmDiscardChanges
    >
      <LoaderSwitch loading={feebackFormLoading || languagesLoading} size="large" noMargin>
        <GuardUnsaved />
        <div>
          {languages.length > 1 && (
            <>
              <SelectLanguage languages={languages} guardChanges={false} supportVariants={false} />
              <br />
            </>
          )}
        </div>
        {feedbackFormType === feedbackFormTypes.BINARY && (
          <>
            <InputField name="binaryHeadline" max={150} label="Headline" multiline />
            <InputField name="binary1" max={15} label="Positive button" />
            <InputField name="binary0" max={15} label="Negative button" />
          </>
        )}
        {[feedbackFormTypes.EMOJIS, feedbackFormTypes.HAPPY_OR_NOT].includes(feedbackFormType) && (
          <>
            <InputField name="emojiHeadline" max={150} label="Headline" multiline />
            <InputField name="emojiGuideline" max={300} label="Explanation" multiline />
          </>
        )}
        {feedbackFormType === feedbackFormTypes.EXTERNAL_LINK && (
          <>
            <InputField name="externalLinkHeadline" max={150} label="Headline" multiline />
            <InputField className="m-b-1" name="externalLinkText" max={150} label="Button text" />
            <InputField className="m-b-2" name="externalLinkUrl" url label="URL" />
          </>
        )}
        {feedbackFormType !== feedbackFormTypes.EXTERNAL_LINK && (
          <>
            <div className={styles.toggle}>
              <ToggleSwitch name="freeformEnabled" className="m-r-md" status={['Include text input field']} />
            </div>
            <Condition when="freeformEnabled" is>
              <>
                <InputField name="freeformDescription" max={300} label="Text input field description" multiline />
                <InputField name="freeformPlaceholder" max={50} label="Text input field placeholder" />
                <InputField name="send" max={15} label="Send button" />
              </>
            </Condition>
          </>
        )}
        <InputField name="back" max={30} label="Back button" />
        <InputField name="thanks" max={300} label="Thanks" multiline />
      </LoaderSwitch>
    </ModalForm>
  );
};

UpdateFeedbackForm.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    feedbackFormType: PropTypes.oneOf(Object.values(feedbackFormTypes)).isRequired,
    botOrSkillParams: BotOrSkillParamsType.isRequired,
  }).isRequired,
};

export default UpdateFeedbackForm;
