import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

// TODO: replace usages of this with frontend/src/features/Organization/types.ts:Member
export default PropTypes.shape({
  id: IDType.isRequired,
  user: PropTypes.shape({
    id: IDType.isRequired,
    email: PropTypes.string,
    username: PropTypes.string,
    profile: PropTypes.shape({
      id: IDType.isRequired,
      avatarUrl: PropTypes.string,
    }).isRequired,
  }).isRequired,
});
