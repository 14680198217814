/* eslint-disable no-use-before-define */
import cx from 'classnames';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';

import { Icon } from 'frontend/components';

import styles from './Button.scss';

export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'dark'
  | 'warning'
  | 'white'
  | 'gray'
  | 'transparent'
  | 'idle'
  | 'kindlyAdmin';
export type ButtonSize = 'small';
export type ButtonIconPosition = 'left' | 'right';

interface LoadingIndicatorProps {
  flat?: boolean;
}
export const LoadingIndicator = ({ flat = false }: LoadingIndicatorProps) => (
  <div
    className={cx(styles.loadingIndicator, {
      [styles.loadingIndicatorFlat]: flat,
    })}
  >
    <span />
    <span />
    <span />
  </div>
);

/** Pick an icon color that contrasts with the button color */
const buttonToIconColor = (color?: ButtonColor | null) => {
  const darkColors = ['primary', 'warning', 'dark'];

  if (color && darkColors.includes(color)) return 'white';
  if (color === 'secondary') return 'black';
  if (color === 'transparent') return 'black';
  return color;
};

interface ButtonContentProps {
  children?: ButtonProps['children'];
  color?: ButtonProps['color'];
  flat?: ButtonProps['flat'];
  icon?: ButtonProps['icon'];
  isSubmitting?: ButtonProps['isSubmitting'];
  text?: ButtonProps['text'];
  title?: ButtonProps['title'];
  iconPosition?: ButtonIconPosition;
}

const ButtonContent = ({
  children,
  color,
  flat,
  icon: IconComponent,
  iconPosition,
  isSubmitting,
  text,
  title,
}: ButtonContentProps) => (
  <>
    {IconComponent && iconPosition === 'left' && (
      <Icon component={IconComponent} color={buttonToIconColor(color)} title={title} />
    )}
    {children || <span>{text}</span>}
    {IconComponent && iconPosition === 'right' && (
      <Icon component={IconComponent} color={buttonToIconColor(color)} title={title} />
    )}
    {isSubmitting && <LoadingIndicator flat={flat} />}
  </>
);

export interface ButtonProps {
  buttonRef?: React.Ref<HTMLButtonElement>;
  children?: React.ReactNode;
  className?: string | undefined;
  /** Button color. */
  color?: ButtonColor;
  disabled?: boolean;
  /** If true, make the button flat (no border or background). */
  flat?: boolean;
  /** External URL, it will be wrapped into a `<a>`. */
  href?: string | undefined;

  /** Icon component (React SVG), shown to the left of button text. */
  icon?: React.ComponentType;
  /** If true, style the button in a loading phase. */
  isSubmitting?: boolean /** Type attribute of `<button>`. */;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
  /** Ref for the button element. */
  /** The shape of the button (e.g. 'circle'). */
  shape?: 'circle';
  size?: ButtonSize;
  span?: boolean;
  /** Button text (or you can use normal children). */
  text?: string | React.ReactElement;
  /** Icon tooltip. */
  title?: string;
  /** Internal URL, it will be wrapped into a `<Link>`. */
  to?: string;
  /** Data object for Google Analytics {category, action, label}. */
  type?: 'button' | 'submit' | 'reset';
  width?: string;
  active?: boolean;
  iconPosition?: ButtonIconPosition;
}
type HTMLProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, keyof ButtonProps>;
type Props = ButtonProps & HTMLProps;

const Button = ({
  children,
  className,
  color = 'secondary',
  disabled = false,
  flat = false,
  href = '',
  icon,
  isSubmitting = false,
  onClick = noop,
  shape,
  size,
  span = false,
  text,
  title,
  to,
  type = 'button',
  width,
  active,
  iconPosition = 'left',
  buttonRef,
  ...rest
}: Props) => {
  const onClickTracking: React.MouseEventHandler<HTMLButtonElement> | undefined = (e) => {
    if (isSubmitting) return;
    onClick(e);
  };

  const classNames = cx(styles.btn, {
    [styles.btnDisabled]: disabled,
    [styles.btnSecondary]: color === 'secondary',
    [styles.btnPrimary]: color === 'primary' || color === 'dark',
    [styles.btnGray]: color === 'gray',
    [styles.btnWarning]: color === 'warning',
    [styles.btnWhite]: color === 'white',
    [styles.btnIdle]: color === 'idle',
    [styles.btnTransparent]: color === 'transparent',
    [styles.btnKindlyAdmin]: color === 'kindlyAdmin',
    [styles.btnSmall]: size === 'small',
    [styles.btnHasIcon]: icon,
    [styles.btnInSpan]: span,
    [styles.btnFlat]: flat,
    [styles.btnIsSubmitting]: isSubmitting,
    [styles.btnCircle]: shape === 'circle',
    [styles.btnFullWidth]: width === 'full',
    [styles.btnLink]: !!href,
    [styles.btnActive]: !!active,
    [styles.btnHasText]: children || text,
  });

  const ourProps = {
    className: cx(classNames, className),
    tabIndex: 0,
    type,
    ...rest,
  };
  const contentProps = { color, flat, icon, isSubmitting, text, title, iconPosition };

  if (href) {
    return (
      <a {...ourProps} href={href}>
        <ButtonContent {...contentProps}>{children}</ButtonContent>
      </a>
    );
  }

  if (to) {
    return (
      <Link {...ourProps} to={to} onClick={onClick}>
        <ButtonContent {...contentProps}>{children}</ButtonContent>
      </Link>
    );
  }

  return (
    // Button type is included in ourProps
    // eslint-disable-next-line react/button-has-type
    <button {...ourProps} onClick={onClickTracking} ref={buttonRef}>
      <ButtonContent {...contentProps}>{children}</ButtonContent>
    </button>
  );
};

export default Button;
