import { useMutation, useQuery } from '@apollo/client';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { HandoverSettingsDocument, UpdateHandoverSettingsDocument } from 'frontend/api/generated';
import { useToast } from 'frontend/hooks';

const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const useHandoverForm = () => {
  const toast = useToast();
  const { botId } = useParams();
  const [updateSettings] = useMutation(UpdateHandoverSettingsDocument, {
    update(cache) {
      /* Invalidate cache for InboxStatusDocument query. */
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'inboxStatus' });
      cache.gc();
    },
  });

  const { data, loading } = useQuery(HandoverSettingsDocument, {
    variables: { botId: botId! },
    skip: !botId,
  });

  const initialValues = useMemo(() => {
    if (loading) return {};
    return {
      businessHourDays: {
        ...weekDays.reduce((accumulator, day) => {
          const timeFrom = data?.handoverSettings[`businessHours${day}From`];
          const timeTo = data?.handoverSettings[`businessHours${day}To`];
          const isClosed = timeFrom === '00:00:00' && timeTo === '00:01:00' ? 'closed' : false;
          const isOpen = timeFrom === '00:00:00' && timeTo === '23:59:00' ? 'open' : false;
          return {
            ...accumulator,
            [day]: {
              status: isClosed || isOpen || 'custom',
              fromHour: timeFrom.slice(0, 2),
              fromMinute: timeFrom.slice(3, 5),
              toHour: timeTo.slice(0, 2),
              toMinute: timeTo.slice(3, 5),
            },
          };
        }, {}),
      },
      ...data?.bot,
      ...data?.handoverSettings,
    };
  }, [data, loading]);

  const onSubmit = useCallback(
    async (values) => {
      const { businessHourDays, businessHoursTimezone } = values;
      await updateSettings({
        variables: {
          botId: botId!,
          handoverInput: {
            ...pick(values, [
              'id',
              'mode',
              'usingBusinessHours',
              'webhookUrl',
              'cleanUpRequestedHandoversAfterDays',
              'cleanUpActiveHandoversAfterDays',
              'endHandoverOnSolve',
            ]),
            businessHoursTimezone,
            ...weekDays.reduce((accumulator, day) => {
              const fromTime =
                businessHourDays[day].status === 'custom'
                  ? `${businessHourDays[day].fromHour}:${businessHourDays[day].fromMinute}:00`
                  : '00:00:00';
              let toTime = `${businessHourDays[day].toHour}:${businessHourDays[day].toMinute}:00`;
              if (businessHourDays[day].status === 'open') toTime = '23:59:00';
              if (businessHourDays[day].status === 'closed') toTime = '00:01:00';
              return {
                ...accumulator,
                [`businessHours${day}From`]: fromTime,
                [`businessHours${day}To`]: toTime,
              };
            }, {}),
          },
        },
      });
    },
    [botId, updateSettings],
  );

  return {
    botId,
    toast,
    loading,
    initialValues,
    onSubmit,
  };
};
