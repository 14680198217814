import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { Trash } from 'frontend/assets/icons';
import { Button, FormErrors, Icon, Input, PageBar, Panel, Select } from 'frontend/components';
import { useModal } from 'frontend/features/Modals';
import { chain, required } from 'frontend/form/validators';
import { httpsRequired } from 'frontend/form/validators/url';
import { IDType } from 'frontend/propTypes';

import DeleteBotWebhookModal from './DeleteBotWebhookModal';
import { addWebhook, getBotWebhooks } from './graphql';
import { WebhookType } from './propTypes';
import styles from './styles.scss';

const WebhookCard = ({ webhook, botId }) => {
  const [showDeleteBotWebhookModal] = useModal(DeleteBotWebhookModal);

  return (
    <div className={styles.webhookCard}>
      <h2>{webhook.title}</h2>
      <h3>{webhook.url}</h3>
      <Icon
        className={styles.remove}
        component={Trash}
        onClick={() => showDeleteBotWebhookModal({ webhookId: webhook.id, title: webhook.title, botId })}
      />
      {webhook.events.map((event) => (
        <span key={event.id}>{event.eventType}</span>
      ))}
    </div>
  );
};

WebhookCard.propTypes = {
  webhook: WebhookType.isRequired,
  botId: IDType.isRequired,
};

const WebhookList = ({ setIsAdding, botWebhooks, botId }) => (
  <Panel className={styles.list}>
    <h3 className={`${styles.sectionTitle} m-b-3`}>Webhooks</h3>
    {Boolean(botWebhooks.length) === false && <p>No webhooks added yet</p>}
    <Button onClick={() => setIsAdding(true)}>Add webhook</Button>
    {botWebhooks.map((hook) => (
      <WebhookCard key={hook.id} webhook={hook} botId={botId} />
    ))}
  </Panel>
);

WebhookList.propTypes = {
  setIsAdding: PropTypes.func.isRequired,
  botWebhooks: PropTypes.arrayOf(WebhookType).isRequired,
  botId: IDType.isRequired,
};

const BotWebhooks = () => {
  const [isAdding, setIsAdding] = useState(false);
  const { botId } = useParams();
  const { data, loading } = useQuery(getBotWebhooks, { variables: { botId } });

  const addToCache = appendArrayInCache({
    variables: { botId },
    pathToItemInMutationData: 'addWebhook',
    pathToArrayInCache: 'botWebhooks',
    query: getBotWebhooks,
  });

  const [add] = useMutation(addWebhook, { update: addToCache });
  const onSubmit = async (values) => {
    try {
      await add({ variables: { botId, ...values } });
      setIsAdding(false);
    } catch (e) {} // eslint-disable-line
  };

  const initialValues = useMemo(
    () => ({
      title: '',
      url: '',
      event: data?.webhookEventTypeChoices?.[Object.keys(data?.webhookEventTypeChoices ?? {})?.[0]],
    }),
    [data],
  );

  if (loading) return null;

  const { botWebhooks, webhookEventTypeChoices } = data;

  if (!isAdding) {
    return <WebhookList setIsAdding={setIsAdding} botWebhooks={botWebhooks} botId={botId} />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <PageBar>
            <Button flat onClick={() => setIsAdding(false)}>
              Go back
            </Button>
            <PageBar.FormButtons />
          </PageBar>
          <FormErrors />
          <Panel>
            <h3 className={`${styles.sectionTitle} m-b-3`}>Webhooks</h3>
            <p>All webhooks from Kindly are POST-calls with a HMAC header, get your HMAC key from Settings/Security</p>
            <p>
              Read how to verify HMAC <a href="https://docs.kindly.ai/hmac">here</a>
            </p>
            <div className={styles.webhookForm}>
              <h4>Instructions for adding Webhook:</h4>
              <ol>
                <li>After filling out this form and clicking save Kindly will do a GET call to your URL</li>
                <li>
                  On your end assert that the GET payload includes the verify_token that matches what you specified
                </li>
                <li>Return 200 OK with a plain/text body that matches the challenge token you specified</li>
                <li>If Kindly receives 200 OK with a valid body the webhook is saved!</li>
              </ol>
              <p>
                You can read more about how to use{' '}
                <a target="_blank" rel="noreferrer" href="https://docs.kindly.ai/api/ticket-webhooks">
                  Ticket webhooks in our documentation
                </a>
                .
              </p>
              <div className={styles.inputContainer}>
                <Field component={Input} name="title" label="Give your webhook a title" validate={required} />
                <Field
                  component={Input}
                  name="url"
                  label="Url to your webhook"
                  validate={chain([required, httpsRequired])}
                />
                <Field
                  component={Input}
                  name="verifyToken"
                  label="Verification token that we send to you"
                  validate={required}
                />
                <Field
                  component={Input}
                  name="challenge"
                  label="Challenge token that you need to respond with (as plain/text)"
                  validate={required}
                />
                <label htmlFor="select-event">Event</label>
                <Field component={Select} name="event" validate={required}>
                  {Object.keys(webhookEventTypeChoices).map((eventType) => (
                    <Select.Option
                      key={webhookEventTypeChoices[eventType]}
                      value={webhookEventTypeChoices[eventType]}
                      label={eventType.replace(/_/g, ' ')}
                    />
                  ))}
                </Field>
              </div>
            </div>
          </Panel>
        </form>
      )}
    />
  );
};

export default BotWebhooks;
