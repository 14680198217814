import { get } from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Plus, Search } from 'frontend/assets/icons';
import { Button, Icon, Input, LoaderSwitch, PageContent } from 'frontend/components';
import { useTabTitle, useToggle } from 'frontend/hooks';

import styles from './Users.scss';
import { PendingUsersList, UsersList } from './components';
import { useFilteredList, useOpenInvite, useSort } from './hooks';
import { useInput, useInvitations, useOrganizationMembers } from '../../hooks';

const Users = () => {
  const { organizationId } = useParams();
  const { members, permissions, loading, organizationName } = useOrganizationMembers({ detailed: true });
  const { invitations, loading: invitationsLoading } = useInvitations(organizationId);
  const hasInvitations = invitations.length > 0;
  const [showPending, togglePending] = useToggle();
  const pendingText = showPending ? 'See users' : 'See pending invitations';
  const openInvite = useOpenInvite(organizationId, organizationName);
  const sort = useSort(showPending);
  const input = useInput('user-filter');

  const canInviteMembers = get(permissions, 'can_invite_members');
  const filteredMembers = useFilteredList(members, { filter: input.value, sortFunction: sort.sortFunction });

  const filteredInvitations = useFilteredList(invitations, {
    filter: input.value,
    sortFunction: sort.sortFunction,
    invitations: true,
  });

  useEffect(() => {
    if (invitations.length === 0 && showPending) togglePending();
  }, [invitations.length, showPending, togglePending]);

  useTabTitle('Users');

  return (
    <PageContent>
      <LoaderSwitch loading={loading || invitationsLoading} size="large">
        <div className={styles.usersMenu}>
          <div>
            {canInviteMembers && <Button onClick={openInvite} color="primary" icon={Plus} text="Invite" />}
            &nbsp; &nbsp;
            {hasInvitations && (
              <Button flat onClick={togglePending}>
                {pendingText}
              </Button>
            )}
          </div>
          <Input
            input={input}
            adornment={<Icon component={Search} />}
            adornmentPosition="left"
            placeholder="Search"
            aria-label="Search"
          />
        </div>
        {showPending ? (
          <PendingUsersList invitations={filteredInvitations} sort={sort} />
        ) : (
          <UsersList members={filteredMembers} sort={sort} organizationId={organizationId} />
        )}
      </LoaderSwitch>
    </PageContent>
  );
};

export default Users;
