import { format } from 'date-fns/format';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { DropdownButton, ImageAvatar, Panel } from 'frontend/components';
import { AVAILABILITY } from 'frontend/features/Inbox/views/ChatViewSelector/AgentAvailability/AgentAvailability';
import { IDType } from 'frontend/propTypes';
import { DATE_FORMAT_DATE } from 'frontend/utils/date';

import styles from './styles.scss';
import { RoleList } from '../../../../components';
import { useOptions } from '../../../../components/MemberList/MemberList';

const UserColumn = ({ user }) => (
  <>
    <ImageAvatar className={styles.avatar} img={get(user, 'profile.avatarUrl')} alt={get(user, 'profile.fullName')} />
    <span>{get(user, 'profile.fullName', user.username || '')}</span>
  </>
);

const Member = ({ user, organizationId, membership, showOptions, shouldShowStatus, botId, isInheritedFromOrg }) => {
  const options = useOptions({ user, botId, organizationId, showOptions, isInheritedFromOrg });

  return (
    <Panel role="row" size="small">
      <div className={styles.userItem}>
        <div role="cell" className={styles.name}>
          {organizationId ? (
            <Link to={`/organization/${organizationId}/user/${user.id}`}>
              <UserColumn user={user} />
            </Link>
          ) : (
            <UserColumn user={user} />
          )}
        </div>
        <div role="cell" className={styles.bots}>
          <span className={styles.sortRoles}>
            {shouldShowStatus ? AVAILABILITY[membership.agentProfile?.availability]?.text : ''}
          </span>
        </div>
        <div role="cell" className={styles.bots}>
          <span className={styles.sortRoles}>{user.username || ''}</span>
        </div>
        <div role="cell" className={styles.bots}>
          <span className={styles.sortRoles}>
            <RoleList roles={membership.roles} />
          </span>
        </div>
        <div role="cell" className={styles.lastLogin}>
          {user.lastLogin ? format(user.lastLogin, DATE_FORMAT_DATE) : ''}
        </div>
        <div role="cell" className={styles.actions}>
          {showOptions ? (
            <DropdownButton wrapperClassName={styles.options} actions={options}>
              <span className={styles.optionsButton} />
            </DropdownButton>
          ) : null}
        </div>
      </div>
    </Panel>
  );
};

Member.propTypes = {
  user: PropTypes.shape({}).isRequired,
  organizationId: IDType.isRequired,
  membership: PropTypes.shape({}).isRequired,
  showOptions: PropTypes.bool.isRequired,
  shouldShowStatus: PropTypes.bool.isRequired,
  botId: IDType,
  isInheritedFromOrg: PropTypes.bool,
};

export default Member;
