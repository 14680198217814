import cx from 'classnames';
import { type MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import type { NotificationType } from 'frontend/api/generated';
import { List } from 'frontend/components';
import { useMarkAsRead } from 'frontend/features/Notifications/hooks';
import { friendlyTimestamp } from 'frontend/utils';

import styles from './Notifications.scss';

export const TICKET_NOTIFICATION_TYPES = ['TicketAssignedNotification', 'TicketCreatedNotification'];
const fixTicketUrl = (url = '') => url.replace('/ticket/', '/chat/');

const NotificationsList = ({ notifications }: { notifications: NotificationType[] }) => {
  const { markAsRead, markAllAsRead } = useMarkAsRead();
  const navigate = useNavigate();
  const markNotificationAsRead = useCallback(
    (item, url) => {
      if (!item.read) markAsRead({ variables: { notificationId: item.id } });
      const pushUrl = fixTicketUrl(url) || `/workspace/${item.botId}/inbox/chat/${item.chatId}`;
      navigate(pushUrl);
    },
    [navigate, markAsRead],
  );

  return (
    <List
      width="380px"
      className={styles.notificationsList}
      dataSource={notifications as Record<string, unknown>[]}
      renderHeader={() => <span>Notifications</span>}
      renderItem={(item: NotificationType) => {
        const notificationItemClassName = cx(styles.notificationItem, {
          [styles.notificationItemRead]: item.read,
          [styles.notificationItemUnread]: !item.read,
        });

        const timestamp = friendlyTimestamp(item.created);

        if (item.notificationType === 'ExchangeNotification') {
          return (
            <List.Item
              onClick={() => markNotificationAsRead(item, item.exchangeUrl)}
              key={item.id}
              className={notificationItemClassName}
            >
              <span>
                {item.user} {item.exchangeEvent} {item.exchangeTitle}
              </span>
              <span className={styles.timestamp}>{timestamp}</span>
            </List.Item>
          );
        }
        if (item.notificationType && TICKET_NOTIFICATION_TYPES.includes(item.notificationType)) {
          return (
            <List.Item
              onClick={() => markNotificationAsRead(item, item.exchangeUrl)}
              key={item.id}
              className={notificationItemClassName}
            >
              <span>{item.exchangeEvent}</span>
              <span className={styles.timestamp}>{timestamp}</span>
            </List.Item>
          );
        }
        return null;
      }}
      renderFooter={() => (
        <button onClick={markAllAsRead as MouseEventHandler} className={styles.markAsRead} type="button">
          Mark all as read
        </button>
      )}
    />
  );
};

export default NotificationsList;
