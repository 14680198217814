import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { type BotApiKeyType, BotApiKeysDocument } from 'frontend/api/generated';
import { Button, DropdownButton, LoaderSwitch, Panel, Table } from 'frontend/components';
import type { Column } from 'frontend/components/Table/Table';
import { useModal } from 'frontend/features/Modals';

import styles from './BotApiKeys.scss';
import { Permission } from './components';
import { CreateBotApiKey, DeleteBotApiKey, ViewBotApiKey } from './modals';

type ActionsType = {
  rowData: BotApiKeyType;
  columnData: {
    showViewBotApiKey: (data: { botId: string; botApiKey: BotApiKeyType }) => void;
    showDeleteBotApiKey: (data: { botId: string; botApiKey: BotApiKeyType }) => void;
    botId: string;
  };
};

const Actions = ({
  rowData: botApiKey,
  columnData: { showViewBotApiKey, showDeleteBotApiKey, botId },
}: ActionsType) => {
  const actions = useMemo(
    () => [
      {
        title: 'View API key',
        key: `view-${botApiKey.id}`,
        onClick: () => showViewBotApiKey({ botId, botApiKey }),
      },
      {
        title: 'Delete API key',
        key: `delete-${botApiKey.id}`,
        onClick: () => showDeleteBotApiKey({ botId, botApiKey }),
      },
    ],
    [botApiKey, botId, showDeleteBotApiKey, showViewBotApiKey],
  );
  if (botApiKey.internalConsumer) {
    return null;
  }
  return <DropdownButton actions={actions} />;
};

type ReadChatColumnType = {
  rowData: {
    viewConversations: boolean;
  };
};

type ReadStatsColumn = {
  rowData: {
    viewStatistics: boolean;
  };
};

const ReadChatColumn = ({ rowData: { viewConversations: checked } }: ReadChatColumnType) => (
  <Permission checked={checked} />
);
const ReadStatsColumn = ({ rowData: { viewStatistics: checked } }: ReadStatsColumn) => <Permission checked={checked} />;

const BotApiKeys = () => {
  const { botId } = useParams();
  const { data, loading } = useQuery(BotApiKeysDocument, { variables: { botId: botId as string } });
  const apiKeys = ((data?.botApiKeys as BotApiKeyType[]) || [])?.filter(({ id }) => id);
  const [showCreateBotApiKey] = useModal(CreateBotApiKey);
  const [showViewBotApiKey] = useModal(ViewBotApiKey);
  const [showDeleteBotApiKey] = useModal(DeleteBotApiKey);
  const columns = useMemo(
    () => [
      { key: 'name' },
      {
        title: 'Read chats',
        key: 'viewConversations',
        render: ReadChatColumn,
      },
      {
        title: 'Read statistics',
        key: 'viewStatistics',
        render: ReadStatsColumn,
      },
      { key: 'actions', component: Actions, data: { showViewBotApiKey, showDeleteBotApiKey, botId } },
    ],
    [showViewBotApiKey, showDeleteBotApiKey, botId],
  );

  return (
    <Panel className={styles.botApiKeysPanel}>
      <div className={styles.botApiKeysHeaderWrapper}>
        <h2 className={styles.header}>API keys</h2>
        <p>
          Use these keys as authorization when working with our APIs.{' '}
          <a href="https://docs.kindly.ai/api" target="_blank" rel="noreferrer">
            Read more in our documentation
          </a>
          .
        </p>
        <Button onClick={() => showCreateBotApiKey({ botId, apiKeys })} color="primary">
          Create API key
        </Button>
      </div>

      <LoaderSwitch size="large" loading={loading}>
        {apiKeys.length > 0 && (
          <Table rightAlignLastColumn className={styles.table} columns={columns as Column[]} data={apiKeys} />
        )}
      </LoaderSwitch>
    </Panel>
  );
};

export default BotApiKeys;
