import { addDays, endOfDay, startOfDay } from 'date-fns';
import type { StartOfWeekOptions } from 'date-fns/startOfWeek';

export const TIME_FORMAT_HOURS = 'HH:mm';
export const TIME_FORMAT_SECONDS_AND_MILLISECONDS = 'ss.SSS';

export const DATE_FORMAT_MONTH = 'dd/MM';
export const DATE_FORMAT_MONTH_NAME = 'dd MMM';
export const DATE_FORMAT_MONTH_NAME_YEAR = 'dd MMM yy';
export const DATE_FORMAT_YEAR = 'yyyy';
export const DATE_FORMAT_YEAR_MONTH = 'yyyy-MM';
export const DATE_FORMAT_DATE = 'dd/MM/yyyy';
export const DATE_FORMAT_DATE_LONG = 'iiii dd/MM/yyyy';
export const DATE_FORMAT_WITH_FULL_TIME =
  `${DATE_FORMAT_DATE} - ${TIME_FORMAT_HOURS}.${TIME_FORMAT_SECONDS_AND_MILLISECONDS}` as const;
export const DATE_FORMAT_MONTH_AND_YEAR = 'MMMM yyyy';
export const DATE_FORMAT_QUARTER_YEAR = 'Q yyyy';
export const DATE_FORMAT_ISO_DATE = 'yyyy-MM-dd';
export const DATE_FORMAT_DAY_MONTH_YEAR = 'dd-MM-yyyy';
export const DATE_FORMAT_YEAR_MONTH_DAY = 'yyyy/MM/dd';
export const DATE_FORMAT_WITH_TIME = `${DATE_FORMAT_DATE} - ${TIME_FORMAT_HOURS}` as const;
export const DATE_FORMAT_WITH_TIME_LONG = `${DATE_FORMAT_DATE_LONG} - ${TIME_FORMAT_HOURS}` as const;
export const DATE_FORMAT_ISO_DATE_TIME = 'yyyy-MM-dd HH:mm';
export const WEEK_STARTS_ON_MONDAY: StartOfWeekOptions = { weekStartsOn: 1 };

export const TODAY_DATE = endOfDay(new Date());
export const SEVEN_DAYS_AGO = startOfDay(addDays(new Date(), -7));

export type DateRange = { startDate: Date; endDate: Date };
