import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { Condition, Input, Select } from 'frontend/components';

import styles from './BusinessHours.scss';

const BusinessHourRow = ({ day }) => (
  <div className={styles.weekdayRow}>
    <strong>{day}</strong>
    <Field component={Select} name={`businessHourDays.${day}.status`}>
      <Select.Option value="open" label="Open" />
      <Select.Option value="closed" label="Closed" />
      <Select.Option value="custom" label="Custom" />
    </Field>
    <Condition when={`businessHourDays.${day}.status`} is="custom">
      <div className={styles.timeInputsWrapper}>
        <div className={cx(styles.timeInputs, styles.timeInputsFrom)}>
          <Field component={Input} aria-label="From (Hour)" maxLength="2" name={`businessHourDays.${day}.fromHour`} />
          <span className={styles.timeInputsColon}>:</span>
          <Field
            component={Input}
            aria-label="From (Minutes)"
            maxLength="2"
            name={`businessHourDays.${day}.fromMinute`}
          />
        </div>
        <span className={styles.timeInputsSeperator}>-</span>
        <div className={cx(styles.timeInputs, styles.timeInputsTo)}>
          <Field component={Input} aria-label="To (Hour)" maxLength="2" name={`businessHourDays.${day}.toHour`} />
          <span className={styles.timeInputsColon}>:</span>
          <Field component={Input} aria-label="To (Minutes)" maxLength="2" name={`businessHourDays.${day}.toMinute`} />
        </div>
      </div>
    </Condition>
  </div>
);

BusinessHourRow.propTypes = {
  day: PropTypes.string.isRequired,
};

export default BusinessHourRow;
