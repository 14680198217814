import Contents from './Contents';
import type { CellProps, Column, RenderFunction, Row } from './Table';
import styles from './Table.scss';

interface TableCellProps {
  data?: Record<string, unknown>;
  render?: RenderFunction;
  component?: React.ComponentType<CellProps>;
  columnName?: string;
  rowData?: Row;
  columnData?: Column;
}

const TableCell = ({ data, render, component, columnName, rowData, columnData }: TableCellProps) => (
  <div className={styles.cell} role="cell">
    <Contents
      data={data}
      render={render}
      rowData={rowData}
      component={component}
      columnName={columnName}
      columnData={columnData}
    />
  </div>
);

export default TableCell;
