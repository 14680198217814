import { gql } from '@apollo/client';

import BOT_ROLE_FRAGMENT from 'frontend/features/Organization/fragments/BotRole.fragment.graphql';
import ORGANIZATION_USER from 'frontend/features/Organization/fragments/OrganizationUser.fragment.graphql';

export default gql`
  query AddBotMembership($id: ID!) {
    bot(id: $id) {
      id
      name
      organization {
        id
        users {
          ...OrganizationUser
        }
      }
      roles {
        ...BotRole
        isDefaultRole
      }
      memberships {
        id
        user {
          id
          email
        }
      }
    }
  }
  ${ORGANIZATION_USER}
  ${BOT_ROLE_FRAGMENT}
`;
