import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BotDocument, ChatsDocument } from 'frontend/api/generated';
import { convertQueryParamsToPersistedValues } from 'frontend/components/NewFilters/utils/helpers';
import { useUrlSearchParams } from 'frontend/hooks';
import { setInsightsChatsLoading, setInsightsSelectedChat } from 'frontend/state/dux/insights';
import { useAppDispatch } from 'frontend/state/hooks';

import { MINIMUM_CHARACTERS_BEFORE_SEARCH } from '../utils/constants';
import dateFilter from '../utils/dateFilter';

// This is hardcoded in backend if noLimit is not set
export const LIMIT = 50;

let previousVariables;

export default () => {
  const [hasMoreChats, setHasMoreChats] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const { botId, chatId } = useParams();
  const [params] = useUrlSearchParams();
  const filters = convertQueryParamsToPersistedValues(false);

  const [page, setPage] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: botData } = useQuery(BotDocument, {
    variables: { botId: botId as string },
  });

  const abortControllerRef = useRef<AbortController>();
  const fetchMoreAbortControllerRef = useRef<AbortController>();

  const onCompleted = (data) => {
    setPage(0);
    if (data?.chats?.chats) {
      setHasMoreChats(data?.chats.chats.length >= LIMIT);
      const foundChat = data.chats.chats.find(({ id }) => id === chatId);
      dispatch(setInsightsSelectedChat(foundChat || data.chats.chats[0]));

      if (!chatId || !foundChat) {
        const navParams = new URLSearchParams(params).toString();
        navigate(`/workspace/${data.chats.chats[0].botId}/insights/${data.chats.chats[0].id}?${navParams}`);
      }
    }
  };

  const cancelOngoingRequests = (cancelFetchMore = true) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
    }
    if (cancelFetchMore && fetchMoreAbortControllerRef.current) {
      fetchMoreAbortControllerRef.current.abort();
      fetchMoreAbortControllerRef.current = new AbortController();
    }
  };

  const variables = useMemo(
    () => ({
      botId,
      ...filters,
      ...dateFilter(filters),
      ...(params.search?.length >= MINIMUM_CHARACTERS_BEFORE_SEARCH && { search: params.search }),
    }),
    [botId, filters, params.search],
  );

  const {
    data,
    loading: areChatsLoading,
    fetchMore,
  } = useQuery(ChatsDocument, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted,
    pollInterval: 20000,
    context: {
      fetchOptions: {
        signal: abortControllerRef.current?.signal,
      },
    },
  });

  useEffect(() => {
    if (!isEqual(variables, previousVariables) && (areChatsLoading || loadingMore)) {
      cancelOngoingRequests(loadingMore);
      previousVariables = variables;
    }
  }, [variables, loadingMore, areChatsLoading]);

  const chats = useMemo(
    () => [...(data?.chats?.chats || [])].sort((a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()),
    [data?.chats?.chats],
  );

  const fetchMoreChats = useCallback(async () => {
    if (chats.length >= LIMIT && hasMoreChats) {
      setLoadingMore(true);
      const newPage = page + 1;

      fetchMoreAbortControllerRef.current = new AbortController();
      try {
        await fetchMore({
          variables: {
            botId,
            ...filters,
            page: newPage,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            if (fetchMoreResult?.chats && fetchMoreResult?.chats.chats.length < LIMIT) {
              setHasMoreChats(false);
            }

            setPage(newPage);

            return {
              chats: {
                ...prev.chats,
                chats: [...(prev.chats?.chats || []), ...(fetchMoreResult.chats?.chats || [])],
              },
            };
          },
          context: {
            fetchOptions: {
              signal: fetchMoreAbortControllerRef.current.signal,
            },
          },
        });
      } catch (err) {
        // ignore
      } finally {
        setLoadingMore(false);
      }
    }
  }, [botId, chats, fetchMore, filters, page, hasMoreChats]);

  useEffect(
    () => () => {
      previousVariables = null;
      cancelOngoingRequests();
    },
    [],
  );

  const handleVisibilitySensorChange = async (isVisible) => {
    if (!isVisible) return;

    if (chats.length > 0 && botData) {
      fetchMoreChats();
    } else setHasMoreChats(false);
  };

  useEffect(() => {
    dispatch(setInsightsChatsLoading(areChatsLoading || loadingMore));
  }, [areChatsLoading, dispatch, loadingMore]);

  return {
    chats,
    areChatsLoading,
    isLoadingMore: loadingMore,
    hasMoreChats,
    total: data?.chats?.total,
    handleVisibilitySensorChange,
  };
};
