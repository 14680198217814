import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { BotMembershipDocument, UpdateBotMembershipDocument } from 'frontend/api/generated';
import { useIsRunning, useToast } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';

import { MEMBERSHIP_TYPES } from '../../constants';
import { UserType } from '../../propTypes';
import { BOT_PERMISSIONS } from '../../queries';
import { membershipRefetches } from '../../utils';
import { UpdateMemberModal } from '../AddMemberModal';
import { getUserName, roleIdsFromFields } from '../AddMemberModal/utils';

const UpdateBotMember = ({ hide, args: { botId, user, organizationId, onBeforeSubmit } }) => {
  const toast = useToast();

  const { data, loading } = useQuery(BotMembershipDocument, {
    variables: { id: botId, userId: user.id },
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(BOT_PERMISSIONS);
  const allPermissions = get(permissionsData, 'botPermissions', []);
  const roles = useMemo(() => get(data, 'bot.roles', []), [data]);
  const currentRoles = useMemo(() => get(data, 'bot.member.membership.roles', []), [data]);
  const users = useMemo(() => [get(data, 'bot.member.user', null)], [data]);
  const name = useMemo(() => get(data, 'bot.name', ''), [data]);

  const refetchQueries = membershipRefetches({ botId, organizationId, membershipType: MEMBERSHIP_TYPES.WORKSPACE });
  const [mutate] = useMutation(UpdateBotMembershipDocument, { refetchQueries });

  const submit = useCallback(
    async (values) => {
      if (typeof onBeforeSubmit === 'function') await onBeforeSubmit();
      const roleIds = roleIdsFromFields(values);
      await mutate({ variables: { botId, userId: user.id, roleIds } });
      toast.success(`${getUserName(user, values.email)}'s ${name} membership was updated!`);
      hide();
    },
    [botId, hide, mutate, name, toast, user, onBeforeSubmit],
  );

  const [onSubmit, isSubmitting] = useIsRunning(submit);

  if (loading || permissionsLoading) return null;

  return (
    <UpdateMemberModal
      currentRoles={currentRoles}
      users={users}
      userId={user.id}
      hide={hide}
      name={name}
      roles={roles}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      allPermissions={allPermissions}
      disallowOrganizationRoles
      organizationId={organizationId}
    />
  );
};

UpdateBotMember.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    botId: IDType.isRequired,
    organizationId: IDType.isRequired,
    user: UserType.isRequired,
    onBeforeSubmit: PropTypes.func,
  }).isRequired,
};

export default UpdateBotMember;
