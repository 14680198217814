import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  type BotMemberType,
  BotMembersDocument,
  type OrganizationMemberType,
  OrganizationMembersDocument,
} from 'frontend/api/generated';

export default ({ id, organizationId }) => {
  const { data: botMembersData, loading: botLoading } = useQuery(BotMembersDocument, { variables: { id } });
  const { data: orgMembersData, loading: orgLoading } = useQuery(OrganizationMembersDocument, {
    variables: { id: organizationId },
    skip: !organizationId,
  });

  const loading = botLoading || orgLoading;

  const botMembers = useMemo(() => {
    if (!botMembersData?.bot?.members) {
      return [] as BotMemberType[];
    }
    return botMembersData.bot.members.filter(({ membership }) => Boolean(membership?.id));
  }, [botMembersData]);

  const organizationMembers = useMemo(() => {
    if (!orgMembersData?.organization?.members) {
      return [] as OrganizationMemberType[];
    }
    return orgMembersData.organization.members.filter(({ membership }) => Boolean(membership.id));
  }, [orgMembersData]);

  /* Annotating bot memberships with every agent's availability, from the organization query. */
  const botMembersWithStatus = botMembers.map((member) => {
    const userId = member.user.id;
    const organizationMember = organizationMembers.find(({ user }) => user.id === userId);
    const orgMemberAvailability = organizationMember?.membership?.agentProfile?.availability ?? 'OFFLINE';

    return {
      ...member,
      membership: {
        ...member.membership,
        agentProfile: {
          availability: orgMemberAvailability,
        },
      },
    };
  });

  return {
    botMembers: botMembersWithStatus,
    organizationMembers,
    loading,
  };
};
