import { gql } from '@apollo/client';

import INVITATION_FRAGMENT from 'frontend/features/Organization/fragments/Invitation.fragment.graphql';

export default gql`
  mutation CreateInvitation($email: String!, $input: InvitationInput!) {
    createInvitation(email: $email, input: $input) {
      ...Invitation
    }
  }
  ${INVITATION_FRAGMENT}
`;
