import { gql } from '@apollo/client';

import ORGANIZATION_META_FRAGMENT from 'frontend/features/Organization/fragments/OrganizationMeta.fragment.graphql';
import ORGANIZATION_ROLE_FRAGMENT from 'frontend/features/Organization/fragments/OrganizationRole.fragment.graphql';
import ORGANIZATION_SSO_FRAGMENT from 'frontend/features/Organization/fragments/OrganizationSso.fragment.graphql';
import SUBSCRIPTION_FRAGMENT from 'frontend/features/Organization/fragments/Subscription.fragment.graphql';

export default gql`
  mutation UpdateOrganizationSettings(
    $id: ID!
    $name: String!
    $slug: String!
    $deleteLogo: Boolean
    $timezone: String!
    $createdCustomRoles: [CustomRoleInput]
    $updatedCustomRoles: [CustomRoleInput]
    $deletedCustomRoles: [CustomRoleInput]
  ) {
    updateOrganization(
      id: $id
      name: $name
      slug: $slug
      deleteLogo: $deleteLogo
      timezone: $timezone
      createdCustomRoles: $createdCustomRoles
      updatedCustomRoles: $updatedCustomRoles
      deletedCustomRoles: $deletedCustomRoles
    ) {
      ...OrganizationMeta
      permissions
      ssoSettings {
        ...OrganizationSso
      }
      roles {
        ...OrganizationRole
      }
      subscriptions {
        ...Subscription
      }
    }
  }
  ${ORGANIZATION_META_FRAGMENT}
  ${ORGANIZATION_SSO_FRAGMENT}
  ${ORGANIZATION_ROLE_FRAGMENT}
  ${SUBSCRIPTION_FRAGMENT}
`;
