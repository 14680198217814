import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useMemo } from 'react';

import { RoleSettings } from '../../../../components';
import { ALL_PERMISSIONS } from '../../../../queries';
import { useOrgSettings } from '../../useSettings';

const EMPTY_STATE_TEXT =
  'This organization has no custom roles. To create one, click the "Create new custom role" button above.';

export default function CustomRoles() {
  const { loading, onSubmit, initialValues } = useOrgSettings();
  const { loading: allPerissionsLoading, data: allPermissionsData } = useQuery(ALL_PERMISSIONS);
  const allPermissions = useMemo(() => get(allPermissionsData, 'allPermissions', []), [allPermissionsData]);

  return (
    <RoleSettings
      loading={loading || allPerissionsLoading}
      onSubmit={onSubmit}
      initialValues={initialValues}
      allPermissions={allPermissions}
      emptyStateText={EMPTY_STATE_TEXT}
    />
  );
}
