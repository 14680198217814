import type { CellProps, RenderFunction } from './Table';

interface ContentsProps extends CellProps {
  render?: RenderFunction;
  component?: React.ComponentType<CellProps>;
}

const Contents = ({ data, rowData, render, component: Component, columnName, columnData }: ContentsProps) => {
  if (render) {
    return render({ data, rowData, columnName, columnData });
  }
  if (Component) {
    return <Component data={data} rowData={rowData} columnName={columnName} columnData={columnData} />;
  }
  return data?.name ?? data;
};
export default Contents;
