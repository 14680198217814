import type { UseFormReturn } from 'react-hook-form';

import { Edit, Trash, Trigger } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { Icon } from 'frontend/components/Icon/Icon';
import ToggleRHF from 'frontend/components/ToggleSwitch/ToggleRHF';
import { useModal } from 'frontend/features/Modals';

import EnableFormModal from './EnableFormModal';
import styles from './ManageFormCard.scss';
import type { CustomerFeedbackInputs } from '../CustomerFeedback';
import PreviewCard from '../NewForm/PreviewCard';
import ratingCardStyles from '../NewForm/RatingScaleSection.scss';
import { type RHF_FORMS_NAME, TRIGGER_TYPES_TO_TEXT } from '../constants';

interface Props {
  rhfFormPath: `${typeof RHF_FORMS_NAME}.${number}`;
  rhfMethods: UseFormReturn<CustomerFeedbackInputs>;
  formIndex: number;
  removeFieldArray: (index: number) => void;
  setFormIndexToEdit: (index: number) => void;
}

export default function ManageFormCard({
  rhfFormPath,
  rhfMethods,
  formIndex,
  removeFieldArray,
  setFormIndexToEdit,
}: Props): React.JSX.Element {
  const { watch } = rhfMethods;
  const { formTitle, ratingScale, isActive, triggerType } = watch(rhfFormPath);

  const [showEnableModal] = useModal(EnableFormModal);

  const deleteForm = () => {
    removeFieldArray(formIndex);
  };

  const editForm = () => {
    setFormIndexToEdit(formIndex);
  };

  const checkIfAnotherFormHasSameTriggerType = () => {
    const allForms = rhfMethods.getValues().feedbackForms;

    const formToEnable = allForms[formIndex];
    const alreadyEnabledForm = allForms.find(
      (form, index) => index !== formIndex && form.triggerType === triggerType && form.isActive,
    );

    if (alreadyEnabledForm && !formToEnable!.isActive) {
      showEnableModal({
        triggerType,
        formToEnable,
        alreadyEnabledForm,
        rhfMethods,
      });
    }
  };

  return (
    <article className={styles.container}>
      <PreviewCard heading={ratingScale.title} className={styles.previewCard}>
        <div className={ratingCardStyles.ratePreviewCardValues}>
          {ratingScale.editLabels.map((editLabel) => (
            <div className={ratingCardStyles.ratePreviewCardValue} key={editLabel.id}>
              <img src={editLabel.image} alt="emoji" />
              {editLabel.showRating && <span>{editLabel.rating}</span>}
            </div>
          ))}
        </div>
      </PreviewCard>

      <section className={styles.triggerType}>
        <div className={styles.formTitle}>
          <span>{formTitle}</span>
          {isActive && <span className={styles.badge}>Active</span>}
        </div>
        <div className={styles.triggerButton}>
          <Icon component={Trigger} />
          {TRIGGER_TYPES_TO_TEXT[triggerType]}
        </div>
      </section>

      <section className={styles.icons}>
        <Button icon={Trash} color="transparent" onClick={deleteForm} />
        <Button icon={Edit} color="transparent" onClick={editForm} />
      </section>

      <ToggleRHF
        name={`feedbackForms.${formIndex}.isActive`}
        rhfMethods={rhfMethods}
        label="Publish form"
        className={styles.publishForm}
        handleToggle={checkIfAnotherFormHasSameTriggerType}
      />
    </article>
  );
}
