import PropTypes from 'prop-types';

import { BotType } from 'frontend/propTypes';

export default PropTypes.shape({
  bots: PropTypes.arrayOf(BotType),
  profile: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
  }),
});
