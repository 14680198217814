import { inRange, set } from 'lodash';

import { HANDOVER_MODE } from 'frontend/features/Inbox/constants';

import { WEEKDAYS } from '../constants';

const secondTimeLater = (fromHour, fromMinutes, toHour, toMinutes) => {
  const fromHourInt = parseInt(fromHour, 10);
  const fromMinutesInt = parseInt(fromMinutes, 10);
  const toHourInt = parseInt(toHour, 10);
  const toMinutesInt = parseInt(toMinutes, 10);
  if (toHourInt > fromHourInt) return true;
  if (toHourInt === fromHourInt && toMinutesInt > fromMinutesInt) return true;
  return false;
};

const isValidTime = (fromHour, fromMinutes, toHour, toMinutes) =>
  // Checks if is number and below clock values
  inRange(fromHour, 24) && inRange(fromMinutes, 60) && inRange(toHour, 24) && inRange(toMinutes, 60);
export default (values) => {
  if (values.mode === HANDOVER_MODE.DISABLED.value) return undefined;
  const errors = {};
  Object.values(WEEKDAYS).forEach((day) => {
    if (
      !isValidTime(
        parseInt(values.businessHourDays[day].fromHour, 10),
        parseInt(values.businessHourDays[day].fromMinute, 10),
        parseInt(values.businessHourDays[day].toHour, 10),
        parseInt(values.businessHourDays[day].toMinute, 10),
      )
    ) {
      const message = `The time 'To' and 'From' on ${day} must be a valid time`;
      set(errors, ['businessHours', day], message);
    }
    if (
      !secondTimeLater(
        parseInt(values.businessHourDays[day].fromHour, 10),
        parseInt(values.businessHourDays[day].fromMinute, 10),
        parseInt(values.businessHourDays[day].toHour, 10),
        parseInt(values.businessHourDays[day].toMinute, 10),
      )
    ) {
      const message = `The time 'To' must be later than the time 'From' on ${day}`;
      set(errors, ['businessHours', day], message);
    }
  });
  return errors;
};
