import { useQuery } from '@apollo/client';
import { Navigate } from 'react-router-dom';

import { MyOrganizationsDocument, type OrganizationType } from 'frontend/api/generated';
import { Loader } from 'frontend/components';
import { OrganizationList } from 'frontend/features/Organization/components';
import useMyPermissions from 'frontend/hooks/useMyPermissions';

export default function OrganizationListRoute() {
  const { loading: loadingOrganizations, data: organizationsData } = useQuery(MyOrganizationsDocument);
  const { platformPermissions, loading: loadingPermissions } = useMyPermissions();

  const loading = loadingOrganizations || loadingPermissions;
  const organizations = organizationsData?.organizations ?? ([] as OrganizationType[]);
  const canAddOrganization = platformPermissions.includes('organizations.add_organization');

  if (loading) {
    return <Loader size="large" />;
  }

  if (!canAddOrganization && organizations.length === 1) {
    return <Navigate to={`/organization/${organizations[0]!.id}`} />;
  }

  return <OrganizationList organizations={organizations} canAddOrganization={canAddOrganization} />;
}
