import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Filter, Search } from 'frontend/assets/icons';
import { Button } from 'frontend/components';
import { useBotOrSkill, useDraftEnabled, useLanguages, useMixpanel } from 'frontend/hooks';
import type { BotOrSkill } from 'frontend/hooks/useBotOrSkill';
import useMyPermissions from 'frontend/hooks/useMyPermissions';
import { selectLibrarySearch, setLibraryFiltersOpen, setLibrarySearchOpen } from 'frontend/state/dux/library';

import styles from './LibraryHeader.scss';
import Back from './components/Back';
import { AddDialoguesButton, AddProactivityButton, LanguagePicker } from '..';
import { LibraryViewNavEnum, navigationPages } from '../../views/NavigationView/NavigationView';
import { LibrarySearchFilters, LibrarySearchInput } from '../LibrarySearch/components';
import PublishChangesButton from '../QuickButtons/PublishChangesButton';

interface LibraryHeaderProps {
  currentView: keyof typeof LibraryViewNavEnum;
  setCurrentView: React.Dispatch<React.SetStateAction<keyof typeof LibraryViewNavEnum>>;
}

export default function LibraryHeader({ currentView, setCurrentView }: LibraryHeaderProps) {
  const { isBot, buildIdObject, botId, buildType } = useBotOrSkill();
  const { languagesAndVariants } = useLanguages({ isBot, buildIdObject, buildType } as BotOrSkill);
  const { isOpen } = useSelector(selectLibrarySearch);
  const isMultiLang = languagesAndVariants?.length > 1;

  const { hasBotPerms } = useMyPermissions({ botId });

  const canViewTemplates = hasBotPerms('view_templates');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { draftEnabled } = useDraftEnabled();
  const onClick = () => {
    if (!canViewTemplates) {
      navigate('../dashboard');
    } else {
      if (isOpen) {
        dispatch(setLibrarySearchOpen(false));
      }
      navigate('');
    }
  };
  const { mixpanel } = useMixpanel();

  const handleShowFilterView = () => {
    dispatch(setLibraryFiltersOpen(true));
    mixpanel.track('Open Bot Improvements filters', { botId });
  };

  return (
    <div className={styles.libraryHeader}>
      <div className={cx(styles.libraryHeaderTitleWrapper, { [styles.libraryHeaderTitleWrapperColumn]: isOpen })}>
        <Back currentView={currentView} onClick={onClick} />

        {!isOpen ? (
          <>
            {!!navigationPages[currentView]?.title && (
              <div className={styles.libraryHeaderTitleLeft}>
                <div className={styles.libraryHeaderTitle}>{navigationPages[currentView]?.title}</div>
              </div>
            )}

            <div className={styles.libraryHeaderTitleRight}>
              {currentView === LibraryViewNavEnum.DIALOGUE && <AddDialoguesButton />}
              {currentView === LibraryViewNavEnum.PROACTIVITY && <AddProactivityButton />}
              {(currentView === LibraryViewNavEnum.DIALOGUE || currentView === LibraryViewNavEnum.NAVIGATION) && (
                <Button
                  className={styles.searchBtn}
                  color="white"
                  icon={Search}
                  onClick={() => {
                    setCurrentView(LibraryViewNavEnum.SEARCH);
                    dispatch(setLibrarySearchOpen(true));
                  }}
                />
              )}
              {currentView === LibraryViewNavEnum.BOT_IMPROVEMENTS && (
                <Button
                  className={styles.filtersBtn}
                  text="Filters"
                  color="white"
                  icon={Filter}
                  onClick={handleShowFilterView}
                />
              )}
            </div>
          </>
        ) : (
          <LibrarySearchInput />
        )}
      </div>
      <div className={styles.languagePickerWrapper}>
        {(currentView === LibraryViewNavEnum.DIALOGUE || currentView === LibraryViewNavEnum.SYSTEM) &&
          isBot &&
          draftEnabled && <PublishChangesButton />}
        {isMultiLang && <LanguagePicker languages={languagesAndVariants} />}
      </div>
      {isOpen && <LibrarySearchFilters />}
    </div>
  );
}
