import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useMemo } from 'react';

import { UserOrganizationMembershipsDocument } from 'frontend/api/generated';

export const useOrganizationUserDetail = ({ organizationId, userId }) => {
  const { data, loading } = useQuery(UserOrganizationMembershipsDocument, { variables: { organizationId, userId } });
  const user = useMemo(() => data?.organization.member?.user, [data]);
  const organizationRoles = useMemo(() => get(data, 'organization.member.membership.roles', []), [data]);
  const botMemberships = data?.organization?.member?.membership?.botMemberships;

  return { loading, user, organizationRoles, botMemberships };
};
