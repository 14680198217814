import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { deleteFromArrayInCache } from 'frontend/api/cacheHelpers';
import { DeleteModal } from 'frontend/features/Modals';
import { IDType } from 'frontend/propTypes';

import { getBotWebhooks, removeWebhook } from './graphql';

const DeleteBotWebhookModal = ({ hide, args: { webhookId, title, botId } }) => {
  const removeFromCache = deleteFromArrayInCache({
    variables: { botId },
    pathToItemInMutationData: 'removeWebhook',
    pathToArrayInCache: 'botWebhooks',
    query: getBotWebhooks,
  });
  const [mutate] = useMutation(removeWebhook, { update: removeFromCache });
  const onSubmit = useCallback(async () => {
    await mutate({ variables: { webhookId } });
  }, [mutate, webhookId]);

  return <DeleteModal hide={hide} onSubmit={onSubmit} name={title} />;
};

DeleteBotWebhookModal.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.shape({
    botId: IDType.isRequired,
    title: PropTypes.string.isRequired,
    webhookId: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteBotWebhookModal;
