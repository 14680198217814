import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';

import {
  OrganizationMembershipDocument,
  UpdateOrganizationMembershipDocument,
  type UserType,
} from 'frontend/api/generated';
import { useIsRunning, useToast } from 'frontend/hooks';

import { MEMBERSHIP_TYPES } from '../../constants';
import { ALL_PERMISSIONS } from '../../queries';
import { membershipRefetches } from '../../utils';
import { UpdateMemberModal } from '../AddMemberModal';
import { getUserName, roleIdsFromFields } from '../AddMemberModal/utils';

type Props = {
  hide: () => Promise<void>;
  args: {
    organizationId: string;
    user: UserType;
  };
};
const UpdateOrganizationMember = ({ hide, args: { organizationId, user } }: Props) => {
  const toast = useToast();

  const { data, loading } = useQuery(OrganizationMembershipDocument, {
    variables: { organizationId, userId: user.id },
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(ALL_PERMISSIONS);
  const allPermissions = get(permissionsData, 'allPermissions', []);
  const roles = useMemo(() => get(data, 'organization.roles', []), [data]);
  const currentRoles = useMemo(() => get(data, 'organization.member.membership.roles', []), [data]);
  const users = useMemo(() => [get(data, 'organization.member.user', null)], [data]);
  const name = useMemo(() => get(data, 'organization.name', ''), [data]);

  const refetchQueries = membershipRefetches({ organizationId, membershipType: MEMBERSHIP_TYPES.ORGANIZATION });
  const [mutate] = useMutation(UpdateOrganizationMembershipDocument, { refetchQueries });

  const submit = useCallback(
    async (values) => {
      const roleIds = roleIdsFromFields(values);
      await mutate({ variables: { organizationId, userId: user.id, roleIds } });
      toast.success(`${getUserName(user, values.email)}'s ${name} membership was updated!`);
      hide();
    },
    [organizationId, hide, mutate, name, toast, user],
  );

  const [onSubmit, isSubmitting] = useIsRunning(submit);

  if (loading || permissionsLoading) return null;

  return (
    <UpdateMemberModal
      currentRoles={currentRoles}
      users={users}
      name={name}
      roles={roles}
      allPermissions={allPermissions}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      hide={hide}
    />
  );
};

export default UpdateOrganizationMember;
