import { defineAction } from 'redux-define';

const PLATFORM = defineAction('platform');

export const SET_LANGUAGE = PLATFORM.defineAction('SET_LANGUAGE');
export const TOGGLE_FAKE_DATA = PLATFORM.defineAction('TOGGLE_FAKE_DATA');

export const setCurrentLanguage = (languageCode: string, variantCode?: string) => ({
  type: SET_LANGUAGE.ACTION,
  languageCode,
  variantCode,
});
export const toggleFakeContent = () => ({
  type: TOGGLE_FAKE_DATA.ACTION,
});

const initialState: PlatformState = {
  currentLanguage: null,
  currentVariant: null,
  fakeDataEnabled: false,
};

export const selectState = (state) => state.platform;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE.ACTION:
      return { ...state, currentLanguage: action.languageCode, currentVariant: action.variantCode };
    case TOGGLE_FAKE_DATA.ACTION:
      return { ...state, fakeDataEnabled: !state.fakeDataEnabled };
    default:
      return state;
  }
};
