import { gql } from '@apollo/client';

import INVITATION_FRAGMENT from 'frontend/features/Organization/fragments/Invitation.fragment.graphql';

export default gql`
  mutation UpdateInvitation($invitationId: ID!, $input: InvitationInput!) {
    updateInvitation(invitationId: $invitationId, input: $input) {
      ...Invitation
    }
  }
  ${INVITATION_FRAGMENT}
`;
