import cx from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { ChatBubble, SplitChatBubble } from 'frontend/components';

import styles from './SuggestionPreview.scss';

const SuggestionPreview = ({ selectedLanguage, className }) => {
  const { values } = useFormState();

  const preFallback = get(values, `preface.${selectedLanguage}`);
  const didYouMean = get(values, `prompt.${selectedLanguage}`) || '';
  const yesButton = get(values, `accept.${selectedLanguage}`);
  const noButton = get(values, `dismiss.${selectedLanguage}`);
  const fallback = get(values, `dismissalResponse.${selectedLanguage}`);

  return (
    <div className={cx(styles.outerContainer, className)}>
      <div className={styles.messageLines}>
        {preFallback && <ChatBubble>{preFallback}</ChatBubble>}
        <ChatBubble className={cx(styles.previewBubble, styles.previewBubbleHasButtons)}>
          {`
            <strong>${didYouMean}</strong>
            <br />
            [suggestion goes here]
            `}
        </ChatBubble>
        <div className={styles.buttonWrapper}>
          {yesButton && <ChatBubble.Button className={styles.button}>{yesButton}</ChatBubble.Button>}
          {noButton && <ChatBubble.Button className={styles.button}>{noButton}</ChatBubble.Button>}
        </div>
        {fallback && <SplitChatBubble text={fallback} hasImage={false} />}
      </div>
    </div>
  );
};

SuggestionPreview.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SuggestionPreview;
