import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ChatbubbleFeedbackDocument, type ChatbubbleFeedbackType } from 'frontend/api/generated';
import { Loader, PageContent, Panel, Table } from 'frontend/components';
import { EmptyState } from 'frontend/components/Stats';
import { useDateRanges, useFeedbackTypes } from 'frontend/features/Analytics/hooks';
import { useAvailableLanguages } from 'frontend/hooks';
import { DATE_FORMAT_DATE, DATE_FORMAT_ISO_DATE } from 'frontend/utils/date';

import styles from './styles.scss';
import FeedbackRating from '../FeedbackRating';

const getColumns = (availableLanguages) => [
  { key: 'created', render: ({ data }) => format(data, DATE_FORMAT_DATE) },
  { key: 'rating', component: FeedbackRating },
  { key: 'freeform' },
  { key: 'language', render: ({ data }) => availableLanguages[data] },
];

const UserFeedbackTable = () => {
  const { botId } = useParams();
  const [currentPage, setPage] = useState(1);
  const { from, to } = useDateRanges();
  const [feedbackFilter] = useFeedbackTypes();
  const { languages, loading: languagesLoading } = useAvailableLanguages();

  const fromDate = format(new Date(from), DATE_FORMAT_ISO_DATE);
  const toDate = format(new Date(to), DATE_FORMAT_ISO_DATE);

  const { data: feedbackData, loading: feedbackLoading } = useQuery(ChatbubbleFeedbackDocument, {
    variables: {
      botId: botId!,
      page: currentPage,
      fromDate,
      toDate,
      feedbackType: feedbackFilter,
    },
    fetchPolicy: 'network-only',
  });

  const pages = feedbackData?.chatbubbleFeedback?.pages || 0;
  const columns = useMemo(
    () => getColumns(Object.fromEntries(languages.map(({ code, name }) => [code, name]))),
    [languages],
  );
  const pagination = useMemo(() => ({ currentPage, pages, setPage }), [currentPage, pages, setPage]);

  const data = useMemo(() => {
    const feedbacks: ChatbubbleFeedbackType[] =
      feedbackData?.chatbubbleFeedback?.objects?.filter((fb): fb is ChatbubbleFeedbackType => Boolean(fb)) ?? [];

    return orderBy(
      feedbacks.map(({ chatId, created, language, rating, freeformText, feedbackType }) => ({
        created,
        language,
        rating: { rating, feedbackType },
        freeform: freeformText,
        to: `/workspace/${botId}/inbox/chat/${chatId}`,
      })),
      'created',
      ['desc'],
    );
  }, [botId, feedbackData?.chatbubbleFeedback?.objects]);

  if (languagesLoading || feedbackLoading) {
    return <Loader size="large" />;
  }

  return (
    <PageContent className={styles.wrapper}>
      {data.length > 0 ? (
        <Table
          className={styles.userFeedback}
          rowClassName={styles.userFeedbackRow}
          data={data}
          columns={columns}
          pagination={pagination}
        />
      ) : (
        <Panel className={styles.panel}>
          <EmptyState />
        </Panel>
      )}
    </PageContent>
  );
};

export default UserFeedbackTable;
