import PropTypes from 'prop-types';

export const WebhookType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      eventType: PropTypes.string.isRequired,
    }),
  ).isRequired,
});
