import { useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { OrganizationSettingsDocument, type OrganizationSettingsQuery } from 'frontend/api/generated';
import { useOnSubmitWithUpload, useToast } from 'frontend/hooks';
import { formatSubmitErrors } from 'frontend/utils';

import { CUSTOM_ROLE_NAME } from '../../constants';
import { UPDATE_ORGANIZATION_SETTINGS } from '../../mutations';
import { getCustomRoleUpdate } from '../../utils';

type HookReturn = {
  loading: boolean;
  initialValues: Partial<
    OrganizationSettingsQuery['organization'] & {
      customRoles: OrganizationSettingsQuery['organization']['roles'];
    }
  >;
  setFile: ((...args) => void) | undefined;
  onSubmit: ((...args) => void) | undefined;
};

export function useOrgSettings(orgId?: string): HookReturn {
  const { organizationId } = useParams();

  const id = orgId || organizationId;

  if (!id) {
    throw new Error('Organization ID is required');
  }

  const toast = useToast();
  const { data, loading } = useQuery(OrganizationSettingsDocument, { variables: { id }, skip: !id });
  const [updateSettings] = useMutation(UPDATE_ORGANIZATION_SETTINGS);

  const onSubmit = async (values, form) => {
    const customRoleUpdate = getCustomRoleUpdate(values, form);
    const variables = {
      id,
      name: values.name,
      slug: values.slug,
      timezone: values.timezone,
      deleteLogo: values.logo === null,
      ...customRoleUpdate,
      ssoSettings: {
        ...omit(values.ssoSettings, ['__typename', 'id']),
        tokenId: values.OauthtokenId,
        tenantDomain: values?.ssoSettings?.tenantDomain || '',
        provider: values?.ssoSettings?.provider || '',
        active: Boolean(values?.ssoSettings?.active),
      },
    };
    try {
      await updateSettings({
        variables,
        update: () => {
          toast.success('Organization settings updated');
        },
      });
    } catch (err) {
      return formatSubmitErrors(err);
    }
    return undefined;
  };

  const initialValues = useMemo(() => {
    const values: Partial<
      OrganizationSettingsQuery['organization'] & {
        customRoles: OrganizationSettingsQuery['organization']['roles'];
      }
    > = {
      ...(data?.organization ?? {}),
      customRoles: (data?.organization.roles || []).filter(({ name }) => name === CUSTOM_ROLE_NAME),
    };

    return values;
  }, [data]);

  const [onSubmitWithUpload, setFile] = useOnSubmitWithUpload(onSubmit, `api/v2/organization/${id}/upload-logo/`);

  return { loading, onSubmit: onSubmitWithUpload, initialValues, setFile };
}
