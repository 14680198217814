import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { BotMetaDocument } from 'frontend/api/generated';
import { Search } from 'frontend/assets/icons';
import { Button, Icon, Input, LoaderSwitch } from 'frontend/components';
import { useMembers } from 'frontend/features/BotDashboard/hooks';
import { useModal } from 'frontend/features/Modals';
import { useInput } from 'frontend/features/Organization/hooks';
import { AddMemberToBot } from 'frontend/features/Organization/modals';
import usersStyle from 'frontend/features/Organization/views/Users/Users.scss';
import { UsersList } from 'frontend/features/Organization/views/Users/components';
import { useFilteredList, useSort } from 'frontend/features/Organization/views/Users/hooks';

import styles from './Membership.scss';

const EmptyBotMembers = () => (
  <div className={styles.emptyBotMembers}>
    This workspace has no memberships. If you want a user to have more permissions than they get from their organization
    membership, click the &quot;Add workspace membership&quot; button.
  </div>
);

const Memberships = () => {
  const { botId } = useParams();
  const { data: botMetaData, loading: botMetaLoading } = useQuery(BotMetaDocument, { variables: { id: botId } });
  const sort = useSort();
  const input = useInput('user-filter');
  const [showAddMemberModal] = useModal(AddMemberToBot);

  const organizationId = get(botMetaData, 'bot.organization.id');
  const organizationName = get(botMetaData, 'bot.organization.name');

  const { botMembers, organizationMembers, loading } = useMembers({ id: botId, organizationId });

  const inheritedMembers = organizationMembers.filter(({ membership }) =>
    membership.roles.find(({ permissions }) => permissions.includes('view_bot')),
  );

  const filteredBotMembers = useFilteredList(botMembers, { filter: input.value, sortFunction: sort.sortFunction });
  const filteredOrganizationMembers = useFilteredList(inheritedMembers, {
    filter: input.value,
    sortFunction: sort.sortFunction,
  });

  const hasBotMembers = botMembers.length > 0;
  const onClickAddMember = useCallback(
    () => showAddMemberModal({ botId, organizationId }),
    [botId, organizationId, showAddMemberModal],
  );

  return (
    <div>
      <div className={usersStyle.usersMenu}>
        <Button onClick={onClickAddMember} color="primary">
          Add workspace membership
        </Button>
        <Input
          input={input}
          adornment={<Icon component={Search} />}
          adornmentPosition="left"
          placeholder="Search"
          aria-label="Search members"
        />
      </div>
      <LoaderSwitch loading={loading || botMetaLoading} size="large">
        <h2>Workspace memberships</h2>
        {hasBotMembers ? (
          <UsersList
            isInheritedFromOrg={false}
            members={filteredBotMembers}
            sort={sort}
            botId={botId}
            organizationId={organizationId}
          />
        ) : (
          <EmptyBotMembers />
        )}
        <br />
        <br />
        <h2>Inherited memberships from organization: {organizationName}</h2>
        <UsersList
          isInheritedFromOrg
          members={filteredOrganizationMembers}
          sort={sort}
          botId={botId}
          organizationId={organizationId}
        />
      </LoaderSwitch>
    </div>
  );
};

export default Memberships;
