import PropTypes from 'prop-types';

import { IDType } from 'frontend/propTypes';

import SubscriptionType from './SubscriptionType';

export default PropTypes.shape({
  id: IDType.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  subscriptions: PropTypes.arrayOf(SubscriptionType),
});
