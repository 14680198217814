import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';

import { ImageAvatar, Input, MultiSelect } from 'frontend/components';
import { Modal } from 'frontend/features/Modals';
import { chain, email as emailValidation, required } from 'frontend/form/validators';

import styles from './styles.scss';
import { UserType } from '../../../../propTypes';

export default function User({ users, existingEmails, organizationId }) {
  const validateUser = useCallback(
    (value) => {
      if (existingEmails.includes((value || '').toLowerCase().trim())) return 'A user with this email already exists';
      return undefined;
    },
    [existingEmails],
  );

  const userOptions = useMemo(
    () =>
      users && [
        ...users.map((user) => {
          const isMember = existingEmails.includes((user.username || '').toLowerCase().trim());
          return {
            value: user.username,
            label: (
              <div
                className={styles.email}
                title={emailValidation(user.username) || (isMember ? 'User with this email already exists' : '')}
              >
                <ImageAvatar img={user.profile.avatarUrl} className={styles.avatar} /> {user.username}
              </div>
            ),
            isDisabled: isMember || emailValidation(user.username),
          };
        }),
      ],
    [users, existingEmails],
  );

  return (
    <Modal.Content className={styles.content}>
      <p className="m-t-0">You can change or remove permissions later.</p>
      {organizationId && (
        <p className={styles.mentionOrgInvite}>
          <br />
          Before inviting a new user to a workspace, you must{' '}
          <Link to={`/organization/${organizationId}/members`}>invite them to your organization</Link>.
        </p>
      )}
      <h4>Email</h4>
      {users ? (
        <Field
          component={MultiSelect}
          name="selectedUsers"
          type="email"
          placeholder="example@example.com"
          options={userOptions}
          maxMenuHeight={150}
          noOptionsMessage={() => 'No more users'}
          validate={(val) => required(val.length)}
          autoFocus
          aria-label="email"
        />
      ) : (
        <Field
          component={Input}
          name="email"
          type="email"
          placeholder="example@example.com"
          validate={chain(required, emailValidation, validateUser)}
          autoFocus
          autoComplete="off"
          aria-label="email"
        />
      )}
    </Modal.Content>
  );
}

User.propTypes = {
  users: PropTypes.arrayOf(UserType),
  existingEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationId: PropTypes.string,
};
