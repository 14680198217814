import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { appendArrayInCache } from 'frontend/api/cacheHelpers';
import { GetAllInvitationsDocument, OrganizationInvitationsDocument } from 'frontend/api/generated';
import { useIsRunning, useToast } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';
import { combineFunctions } from 'frontend/utils';

import { CREATE_INVITATION } from '../../mutations';
import { ADD_ORGANIZATION_MEMBERSHIP_QUERY, ALL_PERMISSIONS } from '../../queries';
import AddMemberModal from '../AddMemberModal';
import { roleIdsFromFields } from '../AddMemberModal/utils';

const adminUpdate = appendArrayInCache({
  pathToArrayInCache: 'allInvitations',
  pathToItemInMutationData: 'createInvitation',
  query: GetAllInvitationsDocument,
});

const AddMemberToOrganization = ({ hide, args: { name, id, onFinished } }) => {
  const toast = useToast();
  const { data, loading } = useQuery(ADD_ORGANIZATION_MEMBERSHIP_QUERY, { variables: { id } });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(ALL_PERMISSIONS);

  const organizationUpdate = appendArrayInCache({
    pathToArrayInCache: 'organizationInvitations',
    pathToItemInMutationData: 'createInvitation',
    query: OrganizationInvitationsDocument,
    variables: { organizationId: id },
  });

  const [mutate] = useMutation(CREATE_INVITATION, { update: combineFunctions(organizationUpdate, adminUpdate) });

  const allPermissions = get(permissionsData, 'allPermissions', []);
  const roles = useMemo(() => get(data, 'organization.roles', []), [data]);
  const currentUserEmails = useMemo(() => get(data, 'organization.users', []).map(({ username }) => username), [data]);

  const submit = useCallback(
    async (values) => {
      const organizationRoleIds = roleIdsFromFields(values);
      const input = { organizationId: id, organizationRoleIds };
      await mutate({ variables: { email: values.email, input } });
      toast.success(`${values.email} was invited to ${name}!`);
      if (onFinished) onFinished();
      else hide();
    },
    [hide, id, mutate, name, onFinished, toast],
  );

  const [onSubmit, isSubmitting] = useIsRunning(submit);

  return (
    <AddMemberModal
      existingEmails={currentUserEmails}
      hide={hide}
      name={name}
      roles={roles}
      allPermissions={allPermissions}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      loading={loading || permissionsLoading}
    />
  );
};

AddMemberToOrganization.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({ id: IDType.isRequired, name: PropTypes.string.isRequired, onFinished: PropTypes.func })
    .isRequired,
};

export default AddMemberToOrganization;
