import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { Modal } from 'frontend/features/Modals';
import { pluralize, prettifyList } from 'frontend/utils';

import styles from './styles.scss';
import { RoleType, UserType } from '../../../../propTypes';
import { getUserName, roleIdsFromFields } from '../../utils';

const Summary = ({ users = [], name, roles }) => {
  const { values } = useFormState();
  const selectedEmails = values.selectedUsers ? values.selectedUsers.map((user) => user.value) : [];
  const fullNames = values.email
    ? [values.email]
    : users
        .filter((user) => (selectedEmails.length ? selectedEmails.includes(user.username) : user))
        .map((user) => getUserName(user, user.email));
  const roleTitles = roleIdsFromFields(values).map((roleId) => roles.find(({ id }) => id === roleId).title);

  return (
    <Modal.Content className={styles.content}>
      <p>You can change the roles, or delete a member at any time.</p>
      <p className={styles.summary}>
        <strong>{prettifyList(fullNames)}</strong> will be {pluralize('member', fullNames.length)} of{' '}
        <strong>{name}</strong>.
        <br />
      </p>
      <p className={styles.summary}>
        The {pluralize('member', fullNames.length)} will have following {pluralize('role', roleTitles.length)}:{' '}
        <strong>{prettifyList(roleTitles)}</strong>.
      </p>
    </Modal.Content>
  );
};

Summary.propTypes = {
  name: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(UserType),
  roles: PropTypes.arrayOf(RoleType),
};

export default Summary;
