import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Button } from 'frontend/components';
import { ChildrenType } from 'frontend/propTypes';

import EmptyCustomRoles from './EmptyCustomRoles';
import styles from './RoleSettings.scss';
import { PermissionsType } from '../../propTypes';
import RoleDetails from '../RoleDetails';
import RolePermissionItem from '../RolePermissionItem';
import RoleTitle from '../RoleTitle';

const createNewCustomRole = (numberOfExistingCustomRoles) => ({
  id: `new-role-${numberOfExistingCustomRoles + 1}`,
  title: 'Custom: NEW',
  permissions: ['view_organization', 'view_bot', 'view_skill'],
});

const noop = () => null;

const CustomRoleSettings = ({ allPermissions, emptyStateText, children }) => {
  const form = useForm();
  const { values } = useFormState();
  const customRoles = useMemo(() => values?.customRoles ?? [], [values]);
  const addNewCustomRole = useCallback(() => {
    form.change('customRoles', [...customRoles, createNewCustomRole(customRoles.length)]);
  }, [customRoles, form]);

  return (
    <>
      <h3 className={styles.header}>
        <Button onClick={addNewCustomRole}>Create new custom role</Button>
      </h3>
      {children}
      {customRoles.length === 0 && <EmptyCustomRoles text={emptyStateText} />}
      {customRoles.length > 0 && (
        <RoleDetails
          roles={customRoles}
          allPermissions={allPermissions}
          rolePermissionComponent={RolePermissionItem}
          roleTitleComponent={RoleTitle}
          showAllPermissions
        />
      )}
      {/* Make sure field is registered: https://github.com/final-form/final-form/issues/169#issuecomment-430939734 */}
      <Field name="customRoles" component={noop} />
    </>
  );
};

CustomRoleSettings.propTypes = {
  allPermissions: PermissionsType.isRequired,
  emptyStateText: PropTypes.string.isRequired,
  children: ChildrenType,
};

export default CustomRoleSettings;
