import { get } from 'lodash';

export const roleIdToFieldName = (id) => `role-${id}`;

export const roleIdsFromFields = (values: string[]): string[] =>
  Object.entries(values)
    .filter(([fieldName, value]) => value && fieldName.startsWith('role-'))
    .map(([fieldName]) => fieldName.split('role-')[1])
    .filter((roleId): roleId is string => Boolean(roleId));

export const customPermissionsFromFields = (values) =>
  Object.entries(values)
    .filter(([fieldName, value]) => value && fieldName.startsWith('can'))
    .map(([fieldName]) => fieldName);

export const getUserName = (user, email) => get(user, 'profile.fullName', email);
