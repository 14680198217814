import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Loader } from 'frontend/components';

import styles from './styles.scss';
import { SkillCard } from '..';
import { SkillType } from '../../propTypes';

const SkillItem = ({ skill }) => {
  const nPermissions = get(skill, 'permissions.length', 0);
  if (nPermissions === 0 || (nPermissions === 1 && skill.permissions[0] === 'view_skill')) {
    return <SkillCard skill={skill} uncklickable />;
  }

  return (
    <Link key={skill.id} to={`/skill/${skill.id}`}>
      <SkillCard skill={skill} />
    </Link>
  );
};

SkillItem.propTypes = {
  skill: SkillType.isRequired,
};

const SkillsList = ({ skills, loading = false }) => {
  if (loading) return <Loader size="large" />;

  return (
    <div className={styles.listContainer}>
      {skills.map((skill) => (
        <SkillItem key={`skill-item-${skill.id}`} skill={skill} />
      ))}
    </div>
  );
};

SkillsList.propTypes = {
  loading: PropTypes.bool,
  skills: PropTypes.arrayOf(SkillType).isRequired,
};

export default SkillsList;
