import PropTypes from 'prop-types';

import { Modal } from 'frontend/features/Modals';

const NoEligibleUsers = ({ hide }) => (
  <Modal title="No eligible users" hide={hide} onOk={hide} hideCancel>
    <Modal.Content>There are no eligible users. Invite them to the organization first!</Modal.Content>
  </Modal>
);

NoEligibleUsers.propTypes = {
  hide: PropTypes.func.isRequired,
};

export default NoEligibleUsers;
