import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { EmbeddedAnalytics } from 'frontend/features/Analytics/components';
import { LUZMO_DASHBOARDS } from 'frontend/features/Analytics/components/EmbeddedAnalytics/constants';
import NotFound from 'frontend/features/NotFound';
import { ORGANIZATION_EXISTS } from 'frontend/features/Organization/queries';
import { Settings, Skills, UserDetail, Users, Workspaces } from 'frontend/features/Organization/views';

import InboxChatRoutes from './InboxChatRoutes';
import OrganizationAnalyticsRoutes from './OrganizationAnalyticsRoutes';
import { useOrganizationRealtimeUpdate } from './hooks';

const OrganizationRoutes = () => {
  const { organizationId: orgId } = useParams();
  const { data, loading, error } = useQuery(ORGANIZATION_EXISTS, { variables: { id: orgId } });

  useOrganizationRealtimeUpdate(orgId);

  if ((!loading && isEmpty(data)) || error) {
    return <NotFound text="Organization not found or missing permissions" />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="dashboard" replace />} />
      <Route path="/analytics/*" element={<OrganizationAnalyticsRoutes />} />
      <Route path="/bots" element={<Navigate to="../workspaces" replace />} />
      <Route path="/dashboard" element={<Navigate to="../workspaces" replace />} />
      <Route path="/workspaces" element={<Workspaces />} />
      <Route path="/inbox/*" element={<InboxChatRoutes />} />
      <Route path="/skills/*" element={<Skills />} />
      <Route path="/users" element={<Users />} /> {/* Deprecated */}
      <Route path="/members/*" element={<Users />} />
      <Route path="/user/:userId" element={<UserDetail />} />
      <Route
        path={LUZMO_DASHBOARDS.ESTIMATED_COSTS.route}
        element={
          <EmbeddedAnalytics
            key={LUZMO_DASHBOARDS.ESTIMATED_COSTS.luzmoSlug}
            integrationLevel="organization"
            dashboardSlug={LUZMO_DASHBOARDS.ESTIMATED_COSTS.luzmoSlug}
            shouldDisplayFilters={false}
          />
        }
      />
      <Route path="/settings/*" element={<Settings />} />
      <Route element={<NotFound />} />
    </Routes>
  );
};

export default OrganizationRoutes;
