import { useMutation, useQuery } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import { OrganizationInvitationDocument } from 'frontend/api/generated';
import { useIsSubmitting, useToast } from 'frontend/hooks';
import { IDType } from 'frontend/propTypes';

import { UPDATE_INVITATION } from '../../mutations';
import { ALL_PERMISSIONS } from '../../queries';
import { UpdateMemberModal } from '../AddMemberModal';
import { roleIdsFromFields } from '../AddMemberModal/utils';

const UpdateInvitation = ({ hide, args: { email, organizationId, id } }) => {
  const toast = useToast();
  const [isSubmitting, submitWrapper] = useIsSubmitting();

  const { data, loading } = useQuery(OrganizationInvitationDocument, {
    variables: { organizationId, invitationId: id },
    fetchPolicy: 'network-only',
  });
  const { data: permissionsData, loading: permissionsLoading } = useQuery(ALL_PERMISSIONS);
  const allPermissions = get(permissionsData, 'allPermissions', []);
  const roles = useMemo(() => get(data, 'organization.roles', []), [data]);
  const currentRoles = useMemo(() => get(data, 'organization.invitation.organizationRoles', []), [data]);
  const name = useMemo(() => get(data, 'organization.name', null), [data]);

  const [mutate] = useMutation(UPDATE_INVITATION);

  const onSubmit = useCallback(
    async (values) => {
      const organizationRoleIds = roleIdsFromFields(values);
      const input = { organizationId, organizationRoleIds };
      await mutate({ variables: { invitationId: id, input } });
      toast.success(`The invitation to ${email} was updated!`);
      hide();
    },
    [email, hide, id, mutate, organizationId, toast],
  );

  if (loading || permissionsLoading) return null;

  return (
    <UpdateMemberModal
      currentRoles={currentRoles}
      hide={hide}
      name={name}
      email={email}
      roles={roles}
      onSubmit={submitWrapper(onSubmit)}
      isSubmitting={isSubmitting}
      allPermissions={allPermissions}
    />
  );
};

UpdateInvitation.propTypes = {
  hide: PropTypes.func.isRequired,
  args: PropTypes.exact({
    organizationId: IDType.isRequired,
    id: IDType.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default UpdateInvitation;
